import { Box, Button } from "@chakra-ui/react";
import React from "react";
import ReviewsLine from "../utils/ReviewsLine";

const reviews1 = [
  {
    name: "Rajesh Kumar",
    review:
      "Before mentorship, I was not able to achieve my daily targets, so I decided to join MenteeMentors. Amit Patel developed my target-making skills and taught me how to score well in JEE. His encouragement and personalized feedback boosted my confidence and performance. Thanks, MenteeMentors!",
    profession: "JEE Aspirant",
    profile: "http://www.resonance.ac.in/photo/17110051.jpg",
  },
  {
    name: "Pratham Sharma",
    review:
      "The mentorship program with Sanya Gupta was incredible! She explained difficult NEET topics clearly and provided excellent study tips. Her support and guidance have significantly improved my academic skills. I am grateful for the opportunities this program has given me. MenteeMentors is fantastic!",
    profession: "NEET Aspirant",
    profile: "http://www.resonance.ac.in/photo/18176410.jpg",
  },
  {
    name: "Vikram Singh",
    review:
      "Before joining MenteeMentors, I struggled with managing my study time effectively. Rajesh Kumar provided practical advice on tackling JEE problems and time management. His mentorship has made a big difference in my preparation. I am extremely satisfied with MenteeMentors and feel fortunate to have participated. Thanks, MenteeMentors!",
    profession: "JEE Aspirant",
    profile: "http://www.resonance.ac.in/photo/16438260.jpg",
  },
  {
    name: "Anuj Kumar",
    review:
      "Working with Meera Nair through the mentorship program was wonderful! She helped me master challenging NEET subjects and provided excellent guidance on study techniques. Her constant support was invaluable. I am very pleased with the outcome and grateful to MenteeMentors for this opportunity.",
    profession: "NEET Aspirant",
    profile: "http://www.resonance.ac.in/photo/17412683.jpg",
  },
  {
    name: "Priya Agarwal",
    review:
      "Before mentorship, I was struggling with JEE physics concepts. Priya Reddy's expertise and practical advice have greatly enhanced my understanding and performance. Her tips on exam techniques and stress management were incredibly helpful. I am thankful to MenteeMentors for connecting me with such a great mentor.",
    profession: "JEE Aspirant",
    profile: "http://www.resonance.ac.in/photo/18176518.jpg",
  },
  {
    name: "Sandeep Sharma",
    review:
      "The mentorship program with Vikram Singh was fantastic! His clear explanations and strategic advice on NEET preparation were immensely helpful. His mentorship has been a key factor in my academic success. I am very satisfied with MenteeMentors and feel fortunate for this opportunity.",
    profession: "NEET Aspirant",
    profile: "http://www.resonance.ac.in/photo/19110525.jpg",
  },
  {
    name: "Ravi Desai",
    review:
      "Before joining MenteeMentors, I lacked a strategic approach to my JEE preparation. Rajesh Kumar provided insightful strategies and was always supportive. His guidance made a significant impact on my study approach. I am very grateful to MenteeMentors for this incredible mentorship opportunity.",
    profession: "JEE Aspirant",
    profile: "http://www.resonance.ac.in/photo/18109964.jpg",
  },
  {
    name: "Sanya Gupta",
    review:
      "Mentoring with Lakshmi Iyer was an excellent experience! Her thorough knowledge and clear explanations of NEET topics were very helpful. Her guidance and support have been invaluable. I am extremely happy with MenteeMentors and the opportunities it has provided me.",
    profession: "NEET Aspirant",
    profile: "https://exprto.com/images/Vivek%20(1).png",
  },
  {
    name: "Arjun Verma",
    review:
      "Amit Patel's mentorship was amazing! His detailed insights and effective strategies for JEE preparation helped me immensely. His encouragement and career advice were invaluable. I am very satisfied with MenteeMentors and feel fortunate to have had such a great mentor.",
    profession: "JEE Aspirant",
    profile: "https://exprto.com/images/Abhinav.png",
  },
  {
    name: "Lakshmi Iyer",
    review:
      "Before mentorship, I struggled to keep up with my NEET syllabus. Arjun Verma's support in understanding complex physics and chemistry concepts was invaluable. His strategic advice on study plans kept me motivated and focused. I am very grateful to MenteeMentors for this transformative experience. Thanks, MenteeMentors!",
    profession: "NEET Aspirant",
    profile: "https://exprto.com/images/Prakhar.png",
  },
];

function Reviews(props) {
  return (
    <Box marginTop={{ base: "15%", lg: "3%" }} marginBottom={"10%"}>
      <Box paddingLeft={"8%"} paddingRight={"8%"}>
        <Button
          colorScheme="teal"
          variant="outline"
          textColor={"#EC490B"}
          border={"1px solid #EC490B"}
          borderRadius={"full"}
          fontSize={{ base: "3xl", lg: "md" }}
          padding={{ base: "30px 50px", lg: "20px 40px" }}
        >
          TESTIMONIAL
        </Button>
      </Box>
      <Box
        fontSize={{ base: "2xl", lg: "5xl" }}
        fontWeight={"extrabold"}
        marginTop={"2%"}
        paddingLeft={"8%"}
        paddingRight={"8%"}
      >
        <h1>Mentees Absolutely</h1>
        <h1>Love Our Mentors</h1>
      </Box>
      <Box marginTop={"5%"}>
        <ReviewsLine reviews={reviews1}></ReviewsLine>
      </Box>
    </Box>
  );
}

export default Reviews;
