import { Box, Button } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

function Footer(props) {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"space-between"}
      backgroundColor={"#000000"}
      padding={"10%"}
      marginTop={{ base: "30%", lg: "0%" }}
      textColor={"whiteAlpha.800"}
    >
      <Box
        display={"flex"}
        flexDirection={{ base: "column", lg: "row" }}
        justifyContent={"space-between"}
        marginBottom={"3%"}
      >
        <Box lineHeight={"7"} minW={{ base: "100%", lg: "70%" }}>
          <Box
            marginTop={"5%"}
            marginBottom={"5%"}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
          >
            <h1>Get started by free chat</h1>
            <h1>with mentor of your choice</h1>
          </Box>
          <Box
            display={"flex"}
            flexDirection={{ base: "column", lg: "row" }}
            justifyContent={"space-between"}
          >
            <Button
              width={"100%"}
              my={{ base: "2%", lg: "0%" }}
              mx={{ base: "0%", lg: "3%" }}
              _hover={{ bgColor: "green.300", textColor: "black" }}
            >
              <Link to="/login">Login</Link>
            </Button>
            <Button
              width={"100%"}
              my={{ base: "2%", lg: "0%" }}
              mx={{ base: "0%", lg: "3%" }}
              _hover={{ bgColor: "green.300", textColor: "black" }}
            >
              <Link to="/become-mentor">Become a mentor</Link>
            </Button>
            <Button
              width="100%"
              my={{ base: "2%", lg: "0%" }}
              mx={{ base: "0%", lg: "3%" }}
              _hover={{ bgColor: "green.300", textColor: "black" }}
              whiteSpace="normal"
              textAlign="center"
              py={6} // Padding for better vertical spacing
            >
              <Link
                to="/mentee-registration"
                style={{
                  color: "inherit",
                  textDecoration: "none",
                  display: "block",
                }}
              >
                Chat/Schedule Meeting With Mentor
              </Link>
            </Button>
          </Box>
        </Box>
        <Box
          display={"flex"}
          flexDirection={{ base: "column", lg: "row" }}
          lineHeight={"7"}
          alignContent={"center"}
          marginTop={{ base: "30px", lg: "0px" }}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={{ base: "center", lg: "flex-start" }}
            marginLeft={{ base: "0px", lg: "50px" }}
          >
            <Box fontWeight={"extrabold"} textColor={"white"}>
              <h1>Resources</h1>
            </Box>
            <p>
              {" "}
              <Link to="/contact-us">Support</Link>
            </p>
          </Box>
        </Box>
      </Box>
      <hr></hr>
      <Box
        display={"flex"}
        flexDirection={{ base: "column", lg: "row" }}
        justifyContent={"space-between"}
        marginTop={"5%"}
      >
        <Box>
          <h1>Khetri, Jhunjhunun, Rajasthan (332746)</h1>
          <h1>
            Copyright @ {new Date().getFullYear()} Ingenuity Software Solutions
            Pvt. Ltd.
          </h1>
        </Box>
        <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
          <Box margin={{ base: "20px 0px", lg: "0px 5px" }}>
            support@menteementors.com
          </Box>
          <Box
            display={"flex"}
            flexDirection={"row"}
            fontSize={"4xl"}
            justifyContent={"space-between"}
            width={"100%"}
            marginTop={{ base: "20px", lg: "10px" }}
          >
            <Box margin={"0px 5px"} borderRadius={"full"}>
              <a
                href="https://www.youtube.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-brands fa-youtube"></i>
              </a>
            </Box>
            <Box margin={"0px 5px"} borderRadius={"full"}>
              <a
                href="https://www.linkedin.com/company/mentee-mentors/?viewAsMember=true"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-brands fa-linkedin"></i>
              </a>
            </Box>
            <Box margin={"0px 5px"} borderRadius={"full"}>
              <a
                href="https://www.instagram.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-brands fa-instagram"></i>
              </a>
            </Box>
            <Box margin={"0px 5px"} borderRadius={"full"}>
              <a
                href="https://t.me/+bFDrFPSI04JlMGM1" // Replace with your actual Telegram link
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-brands fa-telegram"></i>
              </a>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Footer;
