import React, { useState } from "react";
import { Box, Heading, Text, VStack } from "@chakra-ui/react";
import Header from "../common/Header";
import Navbar from "../common/Navbar";
import Footer from "../common/Footer";

const JEEPreparationArticle = () => {
  const [openNavbar, setOpenNavbar] = useState(false);

  return (
    <Box>
      <Box textAlign={"center"} opacity={openNavbar ? "0.1" : "1"}>
        <Header openNavbar={openNavbar} setOpenNavbar={setOpenNavbar}></Header>
      </Box>
      <Box>
        <Navbar openNavbar={openNavbar} setOpenNavbar={setOpenNavbar}></Navbar>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        padding={"5%"}
        paddingBottom={{ base: "0%", lg: "5%" }}
        paddingTop={{ base: "40%", lg: "10%" }}
        marginBottom={{ base: "0%", lg: "0%" }}
        opacity={openNavbar ? "0.1" : "1"}
        onClick={() => setOpenNavbar(false)}
      >
        <Box p={4} maxW="800px" mx="auto">
          <VStack spacing={4} align="stretch">
            <Heading as="h1" size="xl">
              I Messed Up My 11th Grade – What Should I Do in 12th While
              Preparing for JEE?
            </Heading>

            <Text fontSize="lg">
              Transitioning from 10th grade to 11th can be overwhelming for many
              students, especially for those aiming to crack competitive exams
              like the JEE. It's not uncommon to feel lost, confused, or even
              anxious after struggling through 11th grade. But, the good news
              is, you still have time in 12th grade to make things right and
              achieve your goal of clearing the JEE.
            </Text>

            <Heading as="h2" size="lg">
              Why Is 11th Grade So Challenging?
            </Heading>

            <Text fontSize="md">
              The transition from 10th to 11th grade is often a shock to
              students. In 10th grade, you're used to a structured curriculum
              with relatively easy concepts and straightforward exams. But when
              you step into 11th, especially if you're pursuing the science
              stream for JEE preparation, things become significantly harder.
              The subjects become much deeper, the pace faster, and the amount
              of self-study needed increases drastically.
            </Text>

            <Text fontSize="md">
              If you come from a board like ICSE or CBSE, you may have noticed
              this change even more. The sheer volume of material can be
              overwhelming, and it's easy to fall behind. However, just because
              11th grade didn’t go as planned doesn't mean your dream of
              cracking the JEE is over. With proper planning and dedication, you
              can still make 12th grade a success and clear the JEE.
            </Text>

            <Heading as="h2" size="lg">
              What You Can Do in 12th to Catch Up for JEE
            </Heading>

            <Text fontSize="md">
              First and foremost, don’t panic. It’s essential to stay calm and
              focus on how to make the most of your time. Here are some
              practical steps to follow if you feel like you messed up 11th
              grade but still want to ace JEE:
            </Text>

            <Heading as="h3" size="md">
              1. Review Your 11th-Grade Syllabus
            </Heading>

            <Text fontSize="md">
              Before diving headfirst into your 12th-grade syllabus, it's
              crucial to go back and review the 11th-grade concepts. Many topics
              in JEE overlap between 11th and 12th grade, so having a strong
              foundation in 11th-grade topics like mechanics, physical
              chemistry, and algebra will be critical. Dedicate some time daily
              to revise and master these subjects.
            </Text>

            <Heading as="h3" size="md">
              2. Create a Detailed Study Plan
            </Heading>

            <Text fontSize="md">
              Time management will be your best friend. Create a structured plan
              that divides your time between revisiting 11th-grade topics and
              staying up to date with your 12th-grade syllabus. Allocate
              specific hours for revision, mock tests, and problem-solving.
              Ensure you include breaks in your schedule to avoid burnout.
            </Text>

            <Heading as="h3" size="md">
              3. Focus on Important Topics for JEE
            </Heading>

            <Text fontSize="md">
              Not all topics in the JEE syllabus carry the same weight. Certain
              topics from both 11th and 12th grade are more frequently tested
              than others. Subjects like kinematics, chemical bonding, calculus,
              and thermodynamics are often highly prioritized in the exam. Focus
              on these high-weightage topics and make sure you practice as many
              questions as possible.
            </Text>

            <Heading as="h3" size="md">
              4. Use High-Quality Study Material
            </Heading>

            <Text fontSize="md">
              Use standard JEE study material from trusted sources such as NCERT
              books, reference books like H.C. Verma for physics, R.D. Sharma
              for mathematics, and O.P. Tandon for chemistry. If possible, join
              a coaching institute or use online platforms that provide quality
              mock tests, video lectures, and personalized doubt-solving
              sessions.
            </Text>

            <Heading as="h3" size="md">
              5. Practice, Practice, Practice
            </Heading>

            <Text fontSize="md">
              Solving previous years' JEE question papers, sample papers, and
              mock tests is vital to your preparation. This not only helps you
              get familiar with the exam pattern but also boosts your
              confidence. Regular practice will help you improve your speed,
              accuracy, and problem-solving skills, which are crucial for
              clearing JEE.
            </Text>

            <Heading as="h3" size="md">
              6. Don’t Neglect Your 12th-Grade Board Exams
            </Heading>

            <Text fontSize="md">
              While JEE preparation might be your primary focus, don’t forget
              the importance of your 12th-grade board exams. Scoring well in
              board exams is crucial for admission into many top engineering
              colleges. Additionally, strong fundamentals in 12th-grade subjects
              will reinforce your JEE preparation.
            </Text>

            <Heading as="h3" size="md">
              7. Seek Guidance and Mentorship
            </Heading>

            <Text fontSize="md">
              If you're feeling lost or confused about how to proceed, seek
              guidance from teachers, mentors, or online communities. Joining a
              mentorship program like{" "}
              <a
                href="https://menteementors.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                MenteeMentors
              </a>{" "}
              can provide personalized guidance and keep you motivated
              throughout your preparation journey.
            </Text>

            <Heading as="h3" size="md">
              8. Stay Positive and Believe in Yourself
            </Heading>

            <Text fontSize="md">
              Perhaps the most important step is maintaining a positive mindset.
              It’s easy to get discouraged after a tough 11th grade, but
              remember that many students face similar challenges. Stay focused,
              work hard, and believe that you can overcome the setbacks and
              succeed in your JEE exam.
            </Text>

            <Heading as="h2" size="lg">
              Conclusion: 12th Grade Is Your Opportunity to Bounce Back
            </Heading>

            <Text fontSize="md">
              While 11th grade may not have gone according to plan, you still
              have the chance to turn things around in 12th grade. With careful
              planning, focused revision, and consistent practice, you can
              improve your grasp on the JEE syllabus and excel in both your
              board exams and competitive exams. Remember, persistence and a
              positive attitude will help you get through this challenging time
              and achieve your dreams.
            </Text>

            <Text fontSize="md" fontWeight="bold">
              Start today, stay committed, and you’ll be surprised by the
              progress you can make! Visit{" "}
              <a
                href="https://menteementors.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                MenteeMentors
              </a>{" "}
              to find your ideal mentor and start your journey to success.
            </Text>
          </VStack>
        </Box>
      </Box>
      <Box
        textAlign={"center"}
        opacity={openNavbar ? "0.1" : "1"}
        onClick={() => setOpenNavbar(false)}
      >
        <Footer></Footer>
      </Box>
    </Box>
  );
};

export default JEEPreparationArticle;
