import { Box } from "@chakra-ui/react";
import "./App.css";
import Login from "./components/auth/Login";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Home from "./components/body/Home";
import VerifyOtp from "./components/auth/VerifyOtp";
import Messages from "./components/dashboard/Messages";
import Availability from "./components/dashboard/Availability";
import Bookings from "./components/dashboard/Bookings";
import Calendar from "./components/dashboard/Calendar";
import Hero from "./components/dashboard/Hero";
import BecomeMentor from "./components/body/BecomeMentor";
import MentorshipPlans from "./components/body/MentorshipPlans";
import ContactUs from "./components/body/ContactUs";
import FAQs from "./components/body/FAQs";
import MentorSignUp from "./components/body/MentorSignUp";
import Profile from "./components/dashboard/Profile";
import MenteeSignup from "./components/body/MenteeSignup";
import Search from "./components/dashboard/Search";
import Terms from "./components/body/Terms";
import PrivacyPolicy from "./components/body/PrivacyPolicy";
import VerifyUser from "./components/dashboard/VerifyUser";
import NotFound from "./components/body/NotFound";
import RefundPolicy from "./components/body/RefundPolicy";
import BlogList from "./components/blogs/Blogs";
import BlogPostNeetClass11thFoundation from "./components/blogs/NeetClass11thFoundation";
import BlogPostPhysicsImprovement from "./components/blogs/PhysicsImprovement";
import MentorshipForJEE from "./components/blogs/WhyMentorshiImportant";
import JeeAndBoardsPrep from "./components/blogs/JeeAndBoardPrep";
import JEEPreparationArticle from "./components/blogs/11thWasteJeePreparationArticle";

const BlogPostWrapper = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const blogId = queryParams.get("id");

  if (blogId === "Building-a-Strong-Class-11-Foundation-for-neet-exam") {
    return <BlogPostNeetClass11thFoundation />;
  }

  if (
    blogId === "Struggling-with-Physics?-How-MenteeMentors-Can-Help-You-Succeed"
  ) {
    return <BlogPostPhysicsImprovement />;
  }

  if (blogId === "mentorship-for-iit-jee") {
    return <MentorshipForJEE />;
  }

  if (blogId === "jee-and-board-prep") {
    return <JeeAndBoardsPrep />;
  }

  if (blogId === "11th-prep-how-to-prepare-for-jee") {
    return <JEEPreparationArticle />;
  }

  // Optionally handle other blog IDs or provide a fallback
  return <BlogList />;
};

function App() {
  return (
    <Box>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/verify-otp" element={<VerifyOtp />} />
          <Route path="/mentorship-plans" element={<MentorshipPlans />} />
          <Route path="/faqs" element={<FAQs />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="/blogs" element={<BlogPostWrapper />} />{" "}
          {/* Handle dynamic blog posts */}
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/become-mentor" element={<BecomeMentor />} />
          <Route path="/mentor-registration" element={<MentorSignUp />} />
          <Route path="/mentee-registration" element={<MenteeSignup />} />
          <Route path="/dashboard" element={<Hero />} />
          <Route path="/dashboard/messages" element={<Messages />} />
          <Route path="/dashboard/calendar" element={<Calendar />} />
          <Route path="/dashboard/availability" element={<Availability />} />
          <Route path="/dashboard/meetings" element={<Bookings />} />
          <Route path="/dashboard/bookings" element={<Bookings />} />
          <Route path="/dashboard/verify-mentor" element={<VerifyUser />} />
          <Route path="/dashboard/profile" element={<Profile />} />
          <Route path="/dashboard/search" element={<Search />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </Box>
  );
}

export default App;
