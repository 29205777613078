import {
  Box,
  Button,
  FormLabel,
  Image,
  Input,
  Link,
  Select,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import Footer from "../common/Footer";
import Header from "../common/Header";
import Navbar from "../common/Navbar";
import { hitBeApi } from "../../apis/api";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const colleges = [
  "IIT Kharagpur",
  "IIT Bombay",
  "IIT Madras",
  "IIT Kanpur",
  "IIT Delhi",
  "IIT Guwahati",
  "IIT Roorkee",
  "IIT Bhubaneswar",
  "IIT Gandhinagar",
  "IIT Hyderabad",
  "IIT Jodhpur",
  "IIT Patna",
  "IIT Ropar",
  "IIT Indore",
  "IIT Mandi",
  "IIT (BHU) Varanasi",
  "IIT Palakkad",
  "IIT Tirupati",
  "IIT Dhanbad (ISM Dhanbad)",
  "IIT Bhilai",
  "IIT Goa",
  "IIT Jammu",
  "IIT Dharwad",
  "NIT Trichy",
  "NIT Surathkal",
  "NIT Warangal",
  "NIT Calicut",
  "NIT Rourkela",
  "NIT Nagpur",
  "NIT Allahabad",
  "NIT Jaipur",
  "NIT Durgapur",
  "NIT Silchar",
  "NIT Jamshedpur",
  "NIT Kurukshetra",
  "NIT Hamirpur",
  "NIT Patna",
  "NIT Raipur",
  "NIT Srinagar",
  "NIT Goa",
  "NIT Delhi",
  "NIT Puducherry",
  "NIT Uttarakhand",
  "NIT Arunachal Pradesh",
  "NIT Manipur",
  "NIT Meghalaya",
  "NIT Mizoram",
  "NIT Nagaland",
  "NIT Sikkim",
  "NIT Andhra Pradesh",
  "NIT Agartala",
  "All India Institute of Medical Sciences (AIIMS), New Delhi",
  "Maulana Azad Medical College (MAMC), New Delhi",
  "Christian Medical College (CMC), Vellore",
  "Armed Forces Medical College (AFMC), Pune",
  "King George's Medical University (KGMU), Lucknow",
  "JIPMER (Jawaharlal Institute of Postgraduate Medical Education and Research), Puducherry",
  "Lady Hardinge Medical College, New Delhi",
  "Grant Medical College, Mumbai",
  "Kasturba Medical College (KMC), Manipal",
  "Stanley Medical College, Chennai",
  "Institute of Medical Sciences, Banaras Hindu University (IMS-BHU), Varanasi",
  "Seth GS Medical College, Mumbai",
  "Madras Medical College, Chennai",
  "Dr. Ram Manohar Lohia Institute of Medical Sciences, Lucknow",
  "Sanjay Gandhi Postgraduate Institute of Medical Sciences, Lucknow",
  "St. John's Medical College, Bangalore",
  "Lokmanya Tilak Municipal Medical College, Mumbai",
  "Jawaharlal Nehru Medical College, Aligarh Muslim University, Aligarh",
  "Mysore Medical College, Mysore",
  "Bangalore Medical College and Research Institute, Bangalore",
  "Government Medical College, Thiruvananthapuram",
  "University College of Medical Sciences, Delhi",
  "PGIMER (Post Graduate Institute of Medical Education and Research), Chandigarh",
  "Sri Ramachandra Medical College and Research Institute, Chennai",
  "Others (None of the above)",
];

const branches = [
  // Engineering Branches
  "Computer Science Engineering",
  "Mechanical Engineering",
  "Electrical Engineering",
  "Civil Engineering",
  "Chemical Engineering",
  "Aerospace Engineering",
  "Biomedical Engineering",
  "Electronics and Communication Engineering",
  "Information Technology",
  "Mechatronics Engineering",
  "Environmental Engineering",
  "Industrial Engineering",
  "Marine Engineering",
  "Metallurgical Engineering",
  "Petroleum Engineering",
  "Automobile Engineering",
  "Robotics Engineering",
  "Software Engineering",
  "Textile Engineering",
  "Agricultural Engineering",

  // Medical (Doctor) Branches
  "MBBS",
  "BDS (Dentistry)",
  "BAMS (Ayurvedic Medicine)",
  "BHMS (Homeopathy Medicine)",
  "BPT (Physiotherapy)",
  "B.V.Sc. (Veterinary Science)",
  "B.Optom. (Optometry)",
  "B.Sc Nursing",
  "B.Pharm (Pharmacy)",
  "BMLT (Medical Lab Technology)",
  "B.Sc Radiology",
  "B.Sc Anesthesia Technology",
  "B.Sc Cardiovascular Technology",
  "B.Sc Neuroscience",
  "B.Sc Psychology",
  "B.Sc Biotechnology",
  "B.Sc Microbiology",
  "B.Sc Nutrition and Dietetics",
  "B.Sc Physiotherapy",
];

const countryCodes = [
  { code: "+1", name: "USA" },
  { code: "+91", name: "India" },
  { code: "+81", name: "Japan" },
  { code: "+65", name: "Singapore" },
  { code: "+49", name: "Germany" },
  { code: "+33", name: "France" },
  { code: "+39", name: "Italy" },
  { code: "+31", name: "Netherlands" },
  { code: "+41", name: "Switzerland" },
  { code: "+44", name: "UK" },
];

function SignUp() {
  const [currentForm, setCurrentForm] = useState(1);
  const [openNavbar, setOpenNavbar] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    gender: "",
    email: "",
    countryCode: "+91",
    expertType: "",
    collegeName: "",
    collegeRollNo: "",
    collegeYear: "",
    helpWith: "",
    admissionYear: "",
    allIndiaRank: "",
    collegeBranch: "",
    state: "",
    city: "",
    numberOfMentees: "",
    languages: "",
    hearAbout: "",
  });

  const [isOtpSent, setIsOtpSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [isOtpBeingSent, setIsOtpBeingSent] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateForm = (fields) => {
    const phonePattern = /^[6-9]\d{9}$/;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    for (const key of fields) {
      if (formData[key] === "") {
        toast({
          title: "Error",
          description: `Please fill out the ${key} field.`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return false;
      }
    }

    if (fields.includes("mobile") && !phonePattern.test(formData.mobile)) {
      toast({
        title: "Error",
        description:
          "Please enter a valid 10-digit Indian phone number starting with 6-9.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return false;
    }

    if (fields.includes("email") && !emailPattern.test(formData.email)) {
      toast({
        title: "Error",
        description: "Please enter a valid email address.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm(Object.keys(formData))) {
      return;
    }

    if (isOtpBeingSent) {
      return;
    }

    try {
      setIsOtpBeingSent(true);

      const fullContactNumber = `${formData.countryCode}${formData.mobile}`;
      await hitBeApi("mentor", "POST", {
        ...formData,
        mobile: fullContactNumber,
      });

      // Send OTP to both email and phone
      await hitBeApi("phone/otp", "POST", { phoneNumber: fullContactNumber });

      toast({
        title: "Success",
        description: "Mentor signup successful!",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      setIsOtpBeingSent(false);
      setIsOtpSent(true);
    } catch (error) {
      setIsOtpBeingSent(false);
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleOtpVerification = async () => {
    try {
      const { mobile } = formData;
      const fullContactNumber = `${formData.countryCode}${mobile}`;
      const response = await hitBeApi(`phone/otp/verify`, "POST", {
        phoneNumber: fullContactNumber,
        otp: parseInt(otp),
      });

      if (response.success) {
        document.cookie = `token=${response?.data?.token}; path=/;`;
        navigate("/dashboard");
        toast({
          title: "Success",
          description: "Mentor signup successful!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        dispatch({
          type: "Set_Login_Phone_Number",
          value: fullContactNumber,
        });
      } else {
        toast({
          title: "Error",
          description: "OTP verification failed. Please try login.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleNextClick = (currentForm) => {
    const fieldsToValidate =
      currentForm === 1
        ? ["name", "mobile", "gender", "email", "expertType"]
        : [
            "collegeName",
            "collegeAddress",
            "admissionYear",
            "allIndiaRank",
            "collegeRollNo",
            "attemptNumber",
            "collegeYear",
            "collegeBranch",
          ];

    if (!validateForm(fieldsToValidate)) {
      return;
    }
    setCurrentForm(currentForm + 1);
  };

  return (
    <Box>
      <Box textAlign={"center"} opacity={openNavbar ? "0.1" : "1"}>
        <Header openNavbar={openNavbar} setOpenNavbar={setOpenNavbar} />
      </Box>
      <Box>
        <Navbar openNavbar={openNavbar} setOpenNavbar={setOpenNavbar} />
      </Box>
      <Box
        padding={{ base: "0% 5%", lg: "5% 5%" }}
        paddingTop={{ base: "50%", lg: "7%" }}
        textAlign={"center"}
        opacity={openNavbar ? "0.1" : "1"}
        onClick={() => setOpenNavbar(false)}
        borderRadius={"20px"}
      >
        <Box
          display={"flex"}
          flexDirection={{ base: "column", lg: "row" }}
          height={"80%"}
          border={"10px"}
          shadow={"0px 0px 20px 0px rgba(0, 0, 0, 0.5)"}
          borderRadius={"20px"}
        >
          <Box
            w={{ base: "100%", lg: "120%" }}
            bgColor={"#FFF2E8"}
            padding={"3% 3%"}
            borderRadius={"20px"}
            verticalAlign={"center"}
            borderTopRightRadius={"0px"}
            borderBottomRightRadius={"0px"}
          >
            <Image
              src="https://images.pexels.com/photos/6325977/pexels-photo-6325977.jpeg?auto=compress&cs=tinysrgb&w=800"
              alt="group"
              height={{ base: "40%", lg: "100%" }}
              w={"120%"}
              borderRadius={"10px"}
            />
          </Box>
          {!isOtpSent ? (
            <Box
              marginLeft={"5%"}
              w={"100%"}
              marginTop={"50px"}
              marginRight={"5%"}
              marginBottom={"5%"}
            >
              <Box
                fontWeight={"extrabold"}
                fontSize={"4xl"}
                textAlign={{ base: "center", lg: "left" }}
              >
                <Text fontSize={"md"} marginTop={"10px"}>
                  <Link
                    href="/login"
                    color="blue.500"
                    textDecoration="underline"
                    _hover={{ color: "blue.700" }}
                  >
                    Login here
                  </Link>{" "}
                  if already registered
                </Text>
              </Box>
              <Box
                fontSize={{ base: "xl", lg: "3xl" }}
                fontWeight={"extrabold"}
                mt={{ base: "30px", lg: "50px" }}
              >
                <h1>Register as a Mentor on MenteeMentors</h1>
              </Box>
              <Box position={"relative"} top="50px" w={"90%"}>
                <hr height="5px" />
              </Box>
              <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-around"}
                w={"90%"}
                marginTop={"30px"}
              >
                <Box
                  textAlign={"center"}
                  fontSize={{ base: "small", lg: "large" }}
                >
                  <Button
                    onClick={() => setCurrentForm(1)}
                    bgColor={currentForm === 1 ? "green.300" : "#EDF2F6"}
                    _hover={{ bgColor: "green.300" }}
                  >
                    1
                  </Button>
                  <p>Basic Details</p>
                </Box>
                <Box
                  textAlign={"center"}
                  fontSize={{ base: "small", lg: "large" }}
                >
                  <Button
                    onClick={() => setCurrentForm(2)}
                    bgColor={currentForm === 2 ? "green.300" : "#EDF2F6"}
                    _hover={{ bgColor: "green.300" }}
                  >
                    2
                  </Button>
                  <p>College Details</p>
                </Box>
                <Box
                  textAlign={"center"}
                  fontSize={{ base: "small", lg: "large" }}
                >
                  <Button
                    onClick={() => setCurrentForm(3)}
                    bgColor={currentForm === 3 ? "green.300" : "#EDF2F6"}
                    _hover={{ bgColor: "green.300" }}
                  >
                    3
                  </Button>
                  <p>Other Details</p>
                </Box>
              </Box>
              <Box w={"90%"}>
                <Box display={currentForm === 1 ? "block" : "none"}>
                  <FormLabel marginTop={"40px"}> Full Name</FormLabel>
                  <Input
                    type="text"
                    placeholder="full name ..."
                    required
                    name="name"
                    onChange={handleChange}
                  />
                  <FormLabel marginTop={"10px"}>Country Code</FormLabel>
                  <Select
                    key="countryCode"
                    name="countryCode"
                    onChange={handleChange}
                    value={formData.countryCode}
                  >
                    {countryCodes.map(({ code, name }) => (
                      <option key={code} value={code}>
                        {name} ({code})
                      </option>
                    ))}
                  </Select>
                  <FormLabel marginTop={"20px"}> Mobile No. </FormLabel>
                  <Input
                    type="text"
                    placeholder="contact number ..."
                    required
                    name="mobile"
                    maxLength={10}
                    onChange={handleChange}
                  />
                  <FormLabel marginTop={"20px"}>Gender</FormLabel>
                  <Select
                    key={"gender"}
                    placeholder={`Select Gender`}
                    required
                    name="gender"
                    onChange={handleChange}
                  >
                    {["Male", "Female"].map((value, index) => (
                      <option key={index} value={value}>
                        {value}
                      </option>
                    ))}
                  </Select>
                  <FormLabel marginTop={"20px"}> Email Id</FormLabel>
                  <Input
                    type="email"
                    placeholder="email id ..."
                    required
                    name="email"
                    onChange={handleChange}
                  />
                  <FormLabel marginTop={"20px"}> Expert Type</FormLabel>
                  <Select
                    key={"expert-type"}
                    placeholder={`Select Expert`}
                    required
                    name="expertType"
                    onChange={handleChange}
                  >
                    {["IIT-JEE", "NEET"].map((value, index) => (
                      <option key={index} value={value}>
                        {value}
                      </option>
                    ))}
                  </Select>
                  <Box textAlign={"right"} marginTop={"20px"}>
                    <Button
                      onClick={() => handleNextClick(1)}
                      _hover={{ bgColor: "green.300" }}
                    >
                      Next
                    </Button>
                  </Box>
                </Box>
                <Box display={currentForm === 2 ? "block" : "none"}>
                  <FormLabel marginTop={"40px"}>Current College</FormLabel>

                  <Select
                    key={"collegeName"}
                    placeholder={`Select College Name`}
                    required
                    name="collegeName"
                    onChange={handleChange}
                  >
                    {colleges.map((value, index) => (
                      <option key={index} value={value}>
                        {value}
                      </option>
                    ))}
                  </Select>
                  <Input
                    type="text"
                    placeholder="if none of the above, then mention here ..."
                    required
                    name="collegeName"
                    onChange={handleChange}
                    mt="20px"
                  />
                  <FormLabel marginTop={"20px"}>
                    College Year. (only current 1st or 2nd year allowed)
                  </FormLabel>
                  <Select
                    key={"collegeYear"}
                    placeholder={`Select College Year`}
                    required
                    name="collegeYear"
                    onChange={handleChange}
                  >
                    {["1st-Year", "2nd-Year"].map((value, index) => (
                      <option key={index} value={value}>
                        {value}
                      </option>
                    ))}
                  </Select>
                  <FormLabel marginTop={"20px"}>
                    College Roll No/Entry No
                  </FormLabel>
                  <Input
                    type="text"
                    placeholder="college entry number ..."
                    required
                    name="collegeRollNo"
                    onChange={handleChange}
                  />
                  <FormLabel marginTop={"20px"}>Year of admission</FormLabel>
                  <Input
                    type="number"
                    placeholder="admission Year ..."
                    required
                    name="admissionYear"
                    onChange={handleChange}
                  />
                  <FormLabel marginTop={"20px"}>All India Rank</FormLabel>
                  <Input
                    type="number"
                    placeholder="all india rank ..."
                    required
                    name="allIndiaRank"
                    onChange={handleChange}
                  />
                  <FormLabel marginTop={"20px"}>College Branch</FormLabel>
                  <Select
                    key={"collegeBranch"}
                    placeholder={`Select Branch`}
                    required
                    name="collegeBranch"
                    onChange={handleChange}
                  >
                    {branches.map((value, index) => (
                      <option key={index} value={value}>
                        {value}
                      </option>
                    ))}
                  </Select>
                  <Box textAlign={"right"} marginTop={"20px"}>
                    <Button
                      onClick={() => handleNextClick(2)}
                      _hover={{ bgColor: "green.300" }}
                    >
                      Next
                    </Button>
                  </Box>
                </Box>
                <Box display={currentForm === 3 ? "block" : "none"}>
                  <FormLabel marginTop={"40px"}>
                    what can you help students in ?
                  </FormLabel>
                  <Input
                    type="text"
                    placeholder="i can help, how to score good marks in physics ..."
                    required
                    name="helpWith"
                    onChange={handleChange}
                  />
                  <FormLabel marginTop={"40px"}>
                    Where did you get to know about MenteeMentors?
                  </FormLabel>
                  <Input
                    type="text"
                    placeholder="i heard from ..."
                    required
                    name="hearAbout"
                    onChange={handleChange}
                  />
                  <FormLabel marginTop={"20px"}>Your Home State</FormLabel>
                  <Input
                    type="text"
                    placeholder="state name ..."
                    required
                    name="state"
                    onChange={handleChange}
                  />
                  <FormLabel marginTop={"20px"}>Your Home City</FormLabel>
                  <Input
                    type="text"
                    placeholder="city name ..."
                    required
                    name="city"
                    onChange={handleChange}
                  />
                  <FormLabel marginTop={"20px"}>
                    Number of mentees you can guide
                  </FormLabel>
                  <Select
                    key={"numberOfMentees"}
                    placeholder={`Select Number of Mentees`}
                    required
                    name="numberOfMentees"
                    onChange={handleChange}
                  >
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value, index) => (
                      <option key={index} value={value}>
                        {value}
                      </option>
                    ))}
                  </Select>
                  <FormLabel marginTop={"20px"}>
                    Languages (comma separated)
                  </FormLabel>
                  <Input
                    type="text"
                    placeholder="hindi, english ..."
                    name="languages"
                    required
                    onChange={handleChange}
                  />
                  <Box textAlign={"right"} marginTop={"20px"}>
                    <Button
                      _hover={{ bgColor: "green.300" }}
                      onClick={handleSubmit}
                    >
                      {isOtpBeingSent ? (
                        <Spinner size={"sm"}></Spinner>
                      ) : (
                        "Continue"
                      )}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box
              mx="5%"
              w={"80%"}
              paddingTop={{ base: "5%", lg: "20%" }}
              marginBottom={"5%"}
            >
              <Box
                fontWeight={"extrabold"}
                fontSize={"4xl"}
                textAlign={{ base: "center", lg: "left" }}
              >
                <h1>Verify OTP</h1>
              </Box>
              <Box
                fontSize={{ base: "md", lg: "2xl" }}
                textAlign={{ base: "center", lg: "left" }}
              >
                <h1>OTP has been sent to your phone number</h1>
              </Box>
              <Input
                placeholder="Enter OTP"
                name="otp"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                marginTop={"10px"}
              />
              <Button
                bg={"green.500"}
                color={"white"}
                width={"100%"}
                _hover={{ bg: "green.600" }}
                marginTop={"10px"}
                onClick={handleOtpVerification}
              >
                Verify OTP
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      <Box
        textAlign={"center"}
        opacity={openNavbar ? "0.1" : "1"}
        onClick={() => setOpenNavbar(false)}
      >
        <Footer />
      </Box>
    </Box>
  );
}

export default SignUp;
