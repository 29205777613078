import React, { useState } from "react";
import { Box, Heading, Text, VStack } from "@chakra-ui/react";
import Header from "../common/Header";
import Navbar from "../common/Navbar";
import Footer from "../common/Footer";

const MentorshipForJEE = () => {
  const [openNavbar, setOpenNavbar] = useState(false);

  return (
    <Box>
      <Box textAlign={"center"} opacity={openNavbar ? "0.1" : "1"}>
        <Header openNavbar={openNavbar} setOpenNavbar={setOpenNavbar}></Header>
      </Box>
      <Box>
        <Navbar openNavbar={openNavbar} setOpenNavbar={setOpenNavbar}></Navbar>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        padding={"5%"}
        paddingBottom={{ base: "0%", lg: "5%" }}
        paddingTop={{ base: "40%", lg: "10%" }}
        marginBottom={{ base: "0%", lg: "0%" }}
        opacity={openNavbar ? "0.1" : "1"}
        onClick={() => setOpenNavbar(false)}
      >
        <Box maxW="800px" mx="auto" p={6} mt={10}>
          <VStack spacing={6} align="stretch">
            {/* SEO-optimized Title */}
            <Heading as="h1" size="xl" textAlign="center" mb={6}>
              Why Personal Mentorship is Essential for JEE Aspirants
            </Heading>

            {/* Introduction */}
            <Text fontSize="lg">
              The race to excel academically, driven by peer and parental
              pressure, has intensified in recent years. Unfortunately, many
              students, especially those preparing for JEE, find themselves
              caught up in a toxic coaching culture that places unrelenting
              pressure on them. The result? Burnout, dejection, and in extreme
              cases, even depression. When students are unable to meet societal
              expectations, they often experience guilt and a loss of
              motivation.
            </Text>

            {/* Importance of Mentorship */}
            <Heading as="h2" size="lg">
              The Role of a Mentor in Your JEE Journey
            </Heading>
            <Text fontSize="lg">
              To stay focused and maintain motivation, a personal mentor can be
              a game-changer. While family and friends may have your best
              interests at heart, they may not fully understand the unique
              challenges that JEE aspirants face. A mentor, who has been through
              the same journey, can offer tailored advice and guidance. They can
              help you navigate both academic and psychological challenges,
              ensuring that you stay on the right track.
            </Text>

            {/* What Does a Mentor Do? */}
            <Heading as="h3" size="md">
              What Exactly Does a Mentor Do?
            </Heading>
            <Text fontSize="lg">
              A mentor is much more than just a guide. They are experienced
              individuals who have walked the same path and know the ins and
              outs of JEE preparation. From boosting your self-confidence to
              helping you choose the right study materials, a mentor is there to
              support you every step of the way. They believe in your potential,
              even when you might doubt yourself.
            </Text>

            {/* How Mentorship Affects JEE Preparation */}
            <Heading as="h2" size="lg">
              How Personal Mentorship Impacts Your JEE Preparation
            </Heading>
            <Text fontSize="lg">
              While it is true that you don't necessarily need a mentor to clear
              JEE, having one significantly increases your chances of success.
              Studies show that mentorship boosts self-confidence and overall
              academic performance. A mentor's personalized guidance can help
              you see problems from a fresh perspective, ensuring you're
              well-prepared for the exam, both academically and mentally.
            </Text>

            {/* Mentoring vs. Online Coaching */}
            <Heading as="h3" size="md">
              Mentorship vs. Online Coaching
            </Heading>
            <Text fontSize="lg">
              While online coaching platforms provide valuable resources, they
              often lack the personal touch needed for effective learning. A
              mentor, on the other hand, forms a genuine connection with you.
              They provide personalized feedback and act as a sounding board for
              your concerns, something that online lectures cannot replace.
              Mentorship blends the best of online and traditional guidance,
              ensuring you receive holistic support.
            </Text>

            {/* Is Mentorship Right for You? */}
            <Heading as="h2" size="lg">
              Is Personal Mentorship the Right Choice for You?
            </Heading>
            <Text fontSize="lg">
              Mentorship can have a profound impact on your JEE preparation. It
              provides you with someone who understands your struggles, helps
              you overcome hurdles, and motivates you to push through even the
              most challenging times. A mentor isn't just a guide—they become a
              friend who genuinely cares about your success.
            </Text>

            {/* Conclusion */}
            <Heading as="h3" size="md">
              Mentors Help You Realize Your True Potential
            </Heading>
            <Text fontSize="lg">
              Many students feel overwhelmed by the pressure of achieving their
              dreams. A mentor is there to reassure you that success is
              achievable and to provide you with practical advice based on their
              own experiences. With the right mentorship, you can confidently
              navigate your JEE preparation, knowing you're supported by someone
              who has been through it all.
            </Text>

            {/* Call to Action */}
            <Box mt={8} textAlign="center">
              <Heading as="h2" size="lg" mb={4}>
                Ready to Take Your JEE Preparation to the Next Level?
              </Heading>
              <Text fontSize="lg" mb={4}>
                Don't face the JEE journey alone. Join menteementors.com today
                and connect with experienced mentors who can guide you toward
                success.
              </Text>
              <a
                href="https://menteementors.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Text fontSize="lg" color="teal.500" fontWeight="bold">
                  Get Started with Your Mentor!
                </Text>
              </a>
            </Box>
          </VStack>
        </Box>
      </Box>
      <Box
        textAlign={"center"}
        opacity={openNavbar ? "0.1" : "1"}
        onClick={() => setOpenNavbar(false)}
      >
        <Footer></Footer>
      </Box>
    </Box>
  );
};

export default MentorshipForJEE;
