import { Box, Img } from "@chakra-ui/react";
import React from "react";
import MentorshipFeature from "../utils/MentorshipFeature";

const mentorshipFeatures = [
  {
    heading: "Personal Mentorship",
    content:
      "Get one-on-one video call help from mentors who guide you for IIT JEE or NEET. Receive study tips and support to improve your preparation.",
  },
  {
    heading: "Live Sessions",
    content:
      "Book live video meetings with your mentor for guidance, motivation, and solving doubts. Get quick feedback to do better in your exams.",
  },
  {
    heading: "Study Planner",
    content:
      "Create a study plan with your mentor's help to focus on your weak and strong areas. Stay on track with your preparation.",
  },
  {
    heading: "Track Progress",
    content:
      "Check your progress with regular feedback from your mentor. Know where you need to improve and stay motivated to succeed.",
  },
  {
    heading: "Motivation Support",
    content:
      "Get continuous support from your mentor to stay focused, even when preparation is tough. Set clear goals to keep moving forward.",
  },
  {
    heading: "Practice Tips",
    content:
      "Learn expert ways to practice and revise for exams like IIT JEE or NEET. Get tips on solving hard questions and managing time better.",
  },
  {
    heading: "Study Materials & Notes",
    content:
      "Get access to notes and study guides from top students. Use the best resources to boost your exam preparation.",
  },
  {
    heading: "Past Papers Review",
    content:
      "Get expert review of past exam papers to learn important question patterns. Improve your chances by learning how to answer better.",
  },
];

function MentorshipIntro() {
  return (
    <>
      <Box
        display="flex"
        flexDirection={{ base: "column", lg: "row" }}
        justifyContent="space-between"
      >
        <Box
          fontSize={{ base: "2xl", lg: "5xl" }}
          fontWeight="bold"
          marginRight="5%"
          marginLeft="5%"
        >
          <Box>
            <Box mb={4}>
              <h1>Mentorship Plans</h1>
            </Box>
            <Box
              fontSize={{ base: "xl", lg: "2xl" }}
              fontWeight="medium"
              mb={8}
            >
              <h1>Select the plan that matches your study goals!</h1>
            </Box>
          </Box>
        </Box>
        <Box>
          <Img
            height="80%"
            borderRadius={"20px"}
            src="https://images.pexels.com/photos/3867001/pexels-photo-3867001.jpeg?auto=compress&cs=tinysrgb&w=600"
            alt="buy-pic"
          />
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection={{ base: "column", lg: "row" }}
        margin={{ base: "20% 0", lg: "10% 0" }}
        border="0px"
        shadow="0px 0px 20px 0px rgba(0, 0, 0, 0.5)"
        padding="40px"
        borderRadius="20px"
        alignItems="center"
        justifyContent="space-around"
      >
        <Box marginRight={{ base: "0px", lg: "50px" }}>
          <Img
            height="100%"
            width="200%"
            borderRadius={"30px"}
            src="https://images.pexels.com/photos/935743/pexels-photo-935743.jpeg?auto=compress&cs=tinysrgb&w=600"
            alt="about-pic"
          />
        </Box>
        <Box
          maxW={{ base: "100%", lg: "40%" }}
          mt={{ base: "40px", lg: "0px" }}
        >
          <Box
            fontSize={{ base: "2xl", lg: "4xl" }}
            fontWeight="extrabold"
            mb={{ base: "20px", lg: "0px" }}
          >
            <h1>About Mentorship</h1>
          </Box>
          <Box
            fontSize={{ base: "md", lg: "xl" }}
            mt={{ base: "0%", lg: "5%" }}
          >
            <h1>
              At MenteeMentors, we connect you with experienced IIT JEE and NEET
              mentors for personalized guidance. Our mentors support you
              throughout your preparation, offering help whenever you need it.
            </h1>
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection={{ base: "column", lg: "row" }}
        margin="20% 0"
        alignItems="center"
      >
        <Box maxW={{ base: "100%", lg: "60%" }}>
          <Box
            fontSize={{ base: "2xl", lg: "5xl" }}
            fontWeight="extrabold"
            lineHeight="shorter"
          >
            <h1>Succeed in NEET or IIT-JEE with Expert Mentor Guidance</h1>
          </Box>
          <Box
            fontSize={{ base: "md", lg: "xl" }}
            fontWeight="normal"
            marginTop={{ base: "10%", lg: "5%" }}
          >
            <h1>
              Get expert advice from recent toppers who understand your
              challenges. Our one-on-one sessions keep you ahead in your
              preparation and help you achieve your goals.
            </h1>
          </Box>
        </Box>
        <Box
          marginLeft={{ base: "0%", lg: "3%" }}
          mt={{ base: "30px", lg: "0px" }}
        >
          <Img
            height={{ base: "50%", lg: "100%" }}
            width={{ base: "100%", lg: "200%" }}
            borderRadius={"30px"}
            src="https://images.pexels.com/photos/1005803/pexels-photo-1005803.jpeg?auto=compress&cs=tinysrgb&w=600"
            alt="crack-exam-pic"
          />
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection={{ base: "column", lg: "row" }}
        flexFlow={{ base: "column-reverse", lg: "row" }}
        margin="20% 0"
        alignItems="center"
      >
        <Box
          marginRight={{ base: "0%", lg: "5%" }}
          mt={{ base: "30px", lg: "0px" }}
        >
          <Img
            src="https://images.pexels.com/photos/3776776/pexels-photo-3776776.jpeg?auto=compress&cs=tinysrgb&w=600"
            alt="benefits-pic"
            height={{ base: "100%", lg: "100%" }}
            width={{ base: "100%", lg: "100%" }}
            borderRadius={"30px"}
          />
        </Box>
        <Box
          maxW={{ base: "100%", lg: "55%" }}
          marginTop={{ base: "30px", lg: "0px" }}
        >
          <Box
            fontSize={{ base: "2xl", lg: "5xl" }}
            fontWeight="extrabold"
            lineHeight="shorter"
          >
            <h1>Benefits of MenteeMentors's Mentorship Program</h1>
          </Box>
          <Box
            fontSize={{ base: "md", lg: "xl" }}
            fontWeight="normal"
            marginTop={{ base: "10%", lg: "5%" }}
          >
            <h1>
              Proper mentorship is crucial for IIT JEE and NEET success.
              MenteeMentors offers access to highly qualified mentors who guide
              you through every step of your preparation. From effective exam
              strategies to personal 1:1 meetings and valuable study materials,
              we provide everything you need to excel.
            </h1>
          </Box>
        </Box>
      </Box>
      <Box textAlign="center" marginBottom="10%">
        <Box fontSize={{ base: "3xl", lg: "5xl" }} fontWeight="extrabold">
          <h1>Mentorship Features</h1>
        </Box>
        <Box
          display="flex"
          flexDirection={{ base: "column", lg: "row" }}
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
          margin="5% 0"
        >
          {mentorshipFeatures.map((feature, index) => (
            <MentorshipFeature feature={feature} key={index} />
          ))}
        </Box>
      </Box>
    </>
  );
}

export default MentorshipIntro;
