import React from "react";
import { Box, Img, Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";

const BecomeMentorIntro = () => (
  <Box
    display="flex"
    flexDirection={{ base: "column", lg: "row" }}
    mt={{ base: "20px", lg: "50px" }}
    alignItems="center"
    bgGradient="linear(to-r, teal.400, blue.500)"
    borderRadius="lg"
    p={{ base: "20px", lg: "40px" }}
    boxShadow="2xl"
    color="white"
  >
    <Img
      src="https://media.licdn.com/dms/image/v2/D5603AQGpaub_7GEQ9w/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1669360681171?e=1729728000&v=beta&t=QOaQ-nkwhdz8co6xMvEHyA6W0DO-Fe0SiinqbJoMjSI"
      alt="Mentor"
      width={{ base: "90%", lg: "40%" }}
      borderRadius="full"
      mb={{ base: "20px", lg: "0" }}
      boxShadow="lg"
      border="4px solid white"
    />
    <Box
      display="flex"
      flexDirection="column"
      pl={{ base: "0", lg: "30px" }}
      textAlign={{ base: "center", lg: "left" }}
    >
      <Box
        fontSize={{ base: "2xl", lg: "4xl" }}
        fontWeight="extrabold"
        mb="20px"
        lineHeight="shorter"
        letterSpacing="wide"
        textAlign={{ base: "center", lg: "left" }}
      >
        <h1>
          <Box as="span" color="teal.300" fontSize={{ base: "3xl", lg: "5xl" }}>
            MenteeMentors
          </Box>{" "}
          is a game-changing platform for both mentees & mentors
        </h1>
      </Box>
      <Box
        fontSize={{ base: "md", lg: "xl" }}
        fontWeight="light"
        mb="20px"
        maxW="800px"
        lineHeight="taller"
        letterSpacing="wide"
      >
        <p>
          "Setting goals is one thing, but knowing how to achieve them is
          another. MenteeMentors mentors come from top Colleges. They understand
          exactly what the exam requires you to do. They're the best people to
          help you find the right way forward."
        </p>
      </Box>
      <Box fontSize="xl" fontWeight="extrabold" mb="20px" letterSpacing="wide">
        <h4>Himanshu Gaud</h4>
        <h5>IIT Delhi</h5>
      </Box>
      <Button
        as={Link}
        to="/mentor-registration"
        bg="white"
        color="teal.600"
        border="2px solid white"
        fontWeight="extrabold"
        borderRadius="full"
        maxW={{ base: "60%", lg: "30%" }}
        my="4%"
        fontSize={{ base: "small", lg: "large" }}
        _hover={{ bg: "green.300", color: "black" }}
        _active={{ transform: "scale(0.95)" }}
        boxShadow="md"
        transition="all 0.3s ease"
      >
        Sign Up as Mentor
      </Button>
    </Box>
  </Box>
);

export default BecomeMentorIntro;
