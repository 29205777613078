import React, { useState } from "react";
import {
  Box,
  Heading,
  Text,
  VStack,
  List,
  ListItem,
  Link,
  Divider,
  Button,
} from "@chakra-ui/react";
import Header from "../common/Header";
import Navbar from "../common/Navbar";
import Footer from "../common/Footer";

const BlogPostNeetClass11thFoundation = () => {
  const [openNavbar, setOpenNavbar] = useState(false);

  return (
    <Box>
      <Box textAlign={"center"} opacity={openNavbar ? "0.1" : "1"}>
        <Header openNavbar={openNavbar} setOpenNavbar={setOpenNavbar}></Header>
      </Box>
      <Box>
        <Navbar openNavbar={openNavbar} setOpenNavbar={setOpenNavbar}></Navbar>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        padding={"5%"}
        paddingBottom={{ base: "0%", lg: "5%" }}
        paddingTop={{ base: "40%", lg: "10%" }}
        marginBottom={{ base: "0%", lg: "0%" }}
        opacity={openNavbar ? "0.1" : "1"}
        onClick={() => setOpenNavbar(false)}
      >
        <VStack spacing="6" align="start">
          <Heading as="h1" size="2xl" color="teal.600">
            Build a Strong Class 11 Foundation for Guaranteed Future Success
          </Heading>
          <Text fontSize="lg" color="gray.700">
            The journey toward academic excellence begins with a solid
            foundation in Class 11. This year is not just a transition, but a
            crucial phase where the groundwork for future academic success is
            laid. At MenteeMentors, we understand this significance and offer
            tailored mentorship solutions to help students build a robust
            foundation for success in competitive exams like IIT-JEE and NEET.
          </Text>
          <Divider marginY="4" borderColor="gray.300" />
          <Heading as="h2" size="xl" color="teal.500">
            Why Class 11 is Pivotal for Academic Success
          </Heading>
          <Text fontSize="lg" color="gray.700">
            In Class 11, students encounter advanced concepts in Mathematics,
            Physics, Chemistry, and Biology. This is where the transition from
            basic concepts to complex topics occurs, critical for success in
            Class 12 and beyond. Mastering these concepts is essential for
            excelling in competitive exams such as IIT-JEE and NEET, making it
            imperative for students to develop a strong understanding.
          </Text>
          <Divider marginY="4" borderColor="gray.300" />
          <Heading as="h2" size="xl" color="teal.500">
            How MenteeMentors Supports Your Journey
          </Heading>
          <Text fontSize="lg" color="gray.700">
            MenteeMentors is dedicated to providing personalized mentorship
            tailored to your unique needs and challenges. Our experienced
            mentors guide you through your academic journey, offering support in
            various ways:
          </Text>
          <List spacing="4" fontSize="lg" color="gray.600">
            <ListItem>
              <strong>Personalized Tutoring:</strong> Our one-on-one tutoring
              sessions are customized to fit your pace and learning style,
              ensuring thorough understanding of complex topics.
            </ListItem>
            <ListItem>
              <strong>Comprehensive Study Plans:</strong> We help create
              detailed study plans that cover the entire Class 11 syllabus,
              assist in setting achievable goals, and provide resources to keep
              you on track.
            </ListItem>
            <ListItem>
              <strong>Exam Preparation:</strong> Start your competitive exam
              preparation early with guidance on solving past papers, taking
              mock tests, and developing effective study strategies.
            </ListItem>
            <ListItem>
              <strong>Skill Development:</strong> We focus on essential skills
              like problem-solving, critical thinking, and time management,
              crucial for exams and future career success.
            </ListItem>
            <ListItem>
              <strong>Support and Motivation:</strong> Our mentors provide
              ongoing support and motivation to help you stay focused and
              overcome obstacles throughout the year.
            </ListItem>
          </List>
          <Divider marginY="4" borderColor="gray.300" />
          <Heading as="h2" size="xl" color="teal.500">
            Success Stories from MenteeMentors
          </Heading>
          <Text fontSize="lg" color="gray.700">
            Numerous students have achieved remarkable improvements in their
            academic performance and reached their desired scores in competitive
            exams through MenteeMentors. Here are some inspiring success
            stories:
          </Text>
          <Text fontSize="lg" color="gray.600" fontStyle="italic">
            "With the guidance of my mentor from MenteeMentors, I was able to
            grasp complex concepts in Physics and Chemistry. Their personalized
            approach made a huge difference in my IIT-JEE exam preparation." –{" "}
            <strong>Aarav Kumar, IIT-JEE Aspirant</strong>
          </Text>
          <Text fontSize="lg" color="gray.600" fontStyle="italic">
            "The study plan and motivational support I received from
            MenteeMentors helped me stay organized and focused. Thanks to my
            mentor, I achieved a top rank in the NEET exam." –{" "}
            <strong>Sanya Patel, NEET Topper</strong>
          </Text>
          <Divider marginY="4" borderColor="gray.300" />
          <Heading as="h2" size="xl" color="teal.500">
            Start Your Journey with MenteeMentors Today
          </Heading>
          <Text fontSize="lg" color="gray.700">
            If you are a Class 11 student aiming to build a strong academic
            foundation and excel in your future endeavors, MenteeMentors is here
            to help. Explore our comprehensive mentorship services designed to
            meet your needs and ensure your success. Visit{" "}
            <Link href="https://menteementors.com" color="teal.500" isExternal>
              menteementors.com
            </Link>{" "}
            to learn more about our offerings and begin your journey towards
            academic success today.
          </Text>
          <Button
            as="a"
            href="/mentee-registration"
            colorScheme="teal"
            size="lg"
            borderRadius="md"
          >
            Sign Up as a Mentor or Mentee
          </Button>
        </VStack>
      </Box>
      <Box
        textAlign={"center"}
        opacity={openNavbar ? "0.1" : "1"}
        onClick={() => setOpenNavbar(false)}
      >
        <Footer></Footer>
      </Box>
    </Box>
  );
};

export default BlogPostNeetClass11thFoundation;
