import React from "react";
import { Box, Text, Button } from "@chakra-ui/react";

function Plans({ onChoosePlan, isUserLoggedIn, setShouldShowPlan }) {
  const plansArray = [
    {
      plan_intro: "Free Plan",
      planFeatures: ["Unlimited chat with any 3 Mentors"],
      planOriginalPrice: 100,
      planReducedPrice: 0,
      planColor: "#FAF8D9",
      planButtonColor: "#FAF8D9",
    },
    {
      plan_intro: "Starter Plan",
      planFeatures: [
        "You can schedule upto 2 meetings with your personal mentor",
        "Unlimited chat with your 1 Mentor",
        "Validity 2 Days",
      ],
      planOriginalPrice: 499,
      planReducedPrice: 299,
      planButtonAction: "Select Plan",
      planColor: "#FAF8D9",
      planButtonColor: "#FAF8D9",
    },
    {
      plan_intro: "Beginner's Plan",
      planFeatures: [
        "You can schedule upto 10 meetings with your personal mentor",
        "Unlimited chat with your 1 Mentor",
        "Test Series Evaluation with Mentor",
        "Personalised Study Plan",
        "Toppers Hand Written Notes",
        "Validity 1 Month",
      ],
      planOriginalPrice: 5999,
      planReducedPrice: 1999,
      planButtonAction: "Select Plan",
      planColor: "#FAF8D9",
      planButtonColor: "#FAF8D9",
    },
    {
      plan_intro: "Accelerator Plan",
      planFeatures: [
        "You can schedule upto 45 meetings with your personal mentor",
        "Unlimited chat with your 1  Mentor",
        "Test Series Evaluation with Mentor",
        "Personalised Study Plan",
        "Toppers Hand Written Notes",
        "Validity 6 Months",
      ],
      planOriginalPrice: 13499,
      planReducedPrice: 8999,
      planButtonAction: "Select Plan",
      planColor: "#FAF8D9",
      planButtonColor: "#FAF8D9",
    },
    {
      plan_intro: "Mega Plan",
      planFeatures: [
        "You can schedule upto 80 meetings with your personal mentor",
        "Unlimited chat with your 1 Mentor",
        "Test Series Evaluation with Mentor",
        "Personalised Study Plan",
        "Toppers Hand Written Notes",
        "Validity 12 Months",
      ],
      planOriginalPrice: 22499,
      planReducedPrice: 12999,
      planButtonAction: "Select Plan",
      planColor: "#8CC28E",
      planButtonColor: "#FDB34A",
    },
  ];

  return (
    <Box
      pt={{ base: "10vh", lg: "0vh" }}
      pb={"20%"}
      height={{ base: "100vh", lg: "100vh" }}
      overflowY={"auto"} // Scrollable container
    >
      {/* Header Section */}
      <Box
        fontSize={{ base: "3xl", lg: "5xl" }}
        fontWeight={"extrabold"}
        textAlign={"center"}
      >
        <h1>
          Best{" "}
          <Text display={"inline"} color={"#FFA150"}>
            Plans
          </Text>{" "}
          for You
        </h1>
        <Box
          height={"5px"}
          width={"200px"}
          bgColor={"#04AB07"}
          margin={"20px auto"}
        />
        {setShouldShowPlan && (
          <Button onClick={() => setShouldShowPlan(false)} mt={"10px"}>
            Close
          </Button>
        )}
      </Box>

      {/* Plans Section */}
      <Box
        display={"flex"}
        flexDirection={{ base: "column", lg: "row" }}
        justifyContent={"center"}
        mt={{ base: "10px", lg: "50px" }}
        flexWrap={"wrap"}
        gap={{ base: "30px", lg: "2%" }} // Adding gap between plan boxes
        id="plans"
      >
        {plansArray.map((plan, indx) => (
          <Box
            key={indx}
            bgColor={plan.planColor}
            padding={"20px"}
            borderRadius={"20px"}
            w={
              isUserLoggedIn
                ? { base: "100%", lg: "50%" }
                : { base: "100%", lg: "34%" }
            }
            marginTop={"40px"}
            boxShadow={"0px 4px 20px rgba(0, 0, 0, 0.1)"}
            _hover={{
              transform: "scale(1.05)",
              boxShadow: "0px 8px 25px rgba(0, 0, 0, 0.15)",
            }}
            transition={"transform 0.2s ease-in-out"} // Smooth hover effect
            position={"relative"}
          >
            {/* Plan Title */}
            <Box
              fontSize={{ base: "xl", lg: "2xl" }}
              fontWeight={"bold"}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <h1>{plan.plan_intro}</h1>
              {indx === 2 && (
                <Text
                  display={"inline"}
                  color={"green"}
                  fontWeight={"bold"}
                  fontSize={{ base: "sm", lg: "lg" }}
                >
                  Bestseller
                </Text>
              )}
            </Box>

            {/* Plan Features */}
            <Box mt={"20px"}>
              {plan.planFeatures.map((feature, index) => (
                <Box
                  key={index}
                  display={"flex"}
                  alignItems={"center"}
                  mb={"10px"}
                >
                  <i
                    className="fa fa-solid fa-check"
                    style={{ color: "#04AB07" }}
                  ></i>
                  <Text
                    ml={"10px"}
                    fontSize={{ base: "md", lg: "lg" }}
                    fontWeight={"normal"}
                    lineHeight={"1.5"}
                  >
                    {feature}
                  </Text>
                </Box>
              ))}
            </Box>

            {/* Pricing Section */}
            <Box mt={{ base: "20px", lg: "40px" }}>
              <Text color={indx === 2 ? "#141414" : "#909F95"}>
                MRP ₹<del>{plan.planOriginalPrice}</del>/-
              </Text>
              <Text fontSize={{ base: "lg", lg: "xl" }} fontWeight={"bold"}>
                Today's Deal ₹{plan.planReducedPrice}/-
              </Text>
            </Box>

            {/* Action Button */}
            {indx > 0 && isUserLoggedIn && (
              <Box mt={{ base: "20px", lg: "40px" }} textAlign={"center"}>
                <Button
                  bgColor={plan.planButtonColor}
                  width={"100%"}
                  py={"15px"}
                  borderRadius={"50px"}
                  fontSize={"lg"}
                  fontWeight={"bold"}
                  onClick={() => onChoosePlan(plan)}
                  _hover={{
                    bgColor: "darken(plan.planButtonColor, 10%)", // Darken on hover
                  }}
                >
                  {plan.planButtonAction}
                </Button>
              </Box>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default Plans;
