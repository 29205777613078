import {
  Avatar,
  Badge,
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Select,
  Text,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "./Navbar";
import { hitBeApi } from "../../apis/api";

const Hero = () => {
  const education = useSelector((state) => state.education);
  const mentor = useSelector((state) => state.mentor);
  const mentee = useSelector((state) => state.mentee);
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const [isReminderBeingSend, setIsReminderBeingSend] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(mentor?.paymentType);
  const [mentorPaymentPhoneNumber, setMentorPaymentPhoneNumber] = useState(
    mentor?.paymentPhoneNumber
  );

  useEffect(() => {
    setPaymentMethod(mentor?.paymentType);
    setMentorPaymentPhoneNumber(mentor?.paymentPhoneNumber);
  }, [mentor]);

  const handleMentorPaymentPhoneNumberChange = (phoneNumber) => {
    // Remove any non-digit characters
    const sanitizedPhoneNumber = phoneNumber.replace(/\D/g, "");

    // Limit to 10 digits
    if (sanitizedPhoneNumber.length <= 10) {
      setMentorPaymentPhoneNumber(sanitizedPhoneNumber);
    }
  };

  const handleSubmitPaymentDetails = async () => {
    try {
      const requestEndpoint = "mentor/" + mentor?._id;
      // Assuming mentor is already an object you have retrieved or created
      mentor.paymentPhoneNumber = `${mentorPaymentPhoneNumber}`;
      mentor.paymentType = paymentMethod;

      await hitBeApi(requestEndpoint, "PUT", mentor);
      toast({
        title: "Payment details submitted.",
        description: "Your payment details have been successfully submitted.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Submission failed.",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleRemindOwner = async () => {
    try {
      if (isReminderBeingSend) {
        return true;
      }
      setIsReminderBeingSend(true);
      const requestEndpoint = `phone/reminder/${mentor?._id}`;
      await hitBeApi(requestEndpoint, "POST", {
        mentorPaymentPhoneNumber,
        paymentMethod,
      });
      toast({
        title: "Reminder sent.",
        description: "A payment reminder has been sent to the owner.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setIsReminderBeingSend(false);
    } catch (error) {
      setIsReminderBeingSend(false);
      toast({
        title: "Reminder failed.",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleLogout = () => {
    document.cookie = "token=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT";
    navigate("/");
    dispatch({ type: "Logout", value: {} });
  };

  const isMobile = useBreakpointValue({ base: true, lg: false });

  return (
    <Box
      display="flex"
      flexDirection={{ base: "column", lg: "row" }}
      justifyContent={"space-between"}
      margin="5%"
    >
      <Navbar />
      <Box
        display="flex"
        flexDirection="column"
        flex="1"
        marginTop="3%"
        maxW={{ base: "100%", lg: "80%" }}
      >
        <Text
          fontSize={{ base: "x-small", lg: "medium" }}
          color="gray.500"
          textAlign="justify"
        >
          {mentee &&
            mentee.paymentPlans &&
            mentee.paymentPlans.length > 0 &&
            `Disclaimer: Your current plan is ${
              mentee.mentorId
                ? mentee.paymentPlans[mentee.paymentPlans.length - 1]
                : "Free Plan"
            }. ${
              mentee.mentorId &&
              `Under this plan, you are entitled to schedule up to ${
                mentee.mentorId
                  ? Math.max(
                      (mentee.maxMeetingsScheduleLimit ?? 0) -
                        (mentee.totalMeetingsScheduledCount ?? 0),
                      0
                    )
                  : 0
              } more meetings with your mentor.`
            }  ${
              !mentee.mentorId &&
              `Additionally, you are permitted to chat with up to ${
                (mentee.maxConversationsCreationLimit ?? 0) -
                (mentee.totalConversationsCreatedCount ?? 0)
              } more mentors.`
            } Please buy a new plan once limit exceeded.`}
          {mentor &&
            !user?.imageUrl &&
            "Disclaimer: Please update your profile picture to enhance the trustworthiness and authenticity of your profile."}
        </Text>
        <Box
          display="flex"
          flexDirection={"row"}
          justifyContent="space-between"
          alignItems={{ base: "flex-start", lg: "center" }}
          mb={4}
        >
          <Box fontSize="2xl" fontWeight="bold">
            <h1>Welcome</h1>
            <h1>{user?.name}</h1>
          </Box>
          <Button
            onClick={handleLogout}
            mt={{ base: 4, lg: 0 }}
            bg="#FCB339"
            _hover={{
              backgroundColor: "#FCB339",
              color: "white",
              boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
            }}
            transition="box-shadow 0.3s"
          >
            Logout
          </Button>
        </Box>
        <Box
          display="flex"
          flexDirection={{ base: "column", lg: "row" }}
          justifyContent="space-between"
          mt={4}
        >
          <Box
            flex="1"
            borderWidth="1px"
            shadow="md"
            borderRadius="20px"
            mr={4}
            padding="3%"
            transition="transform 0.3s, box-shadow 0.3s"
            _hover={{
              transform: "scale(1.05)",
              boxShadow: "0 4px 8px rgba(0,0,0,0.3)",
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              mb={4}
              justifyContent="space-between"
            >
              <Box
                zIndex={"1000"}
                _hover={{
                  transform: "scale(5.0)", // Increase the size on hover
                  transition: "transform 1.4s ease-in-out", // Smooth transition effect
                }}
              >
                <Avatar
                  name={user?.name}
                  src={user?.imageUrl}
                  height="80px"
                  width="80px"
                  borderRadius="full"
                  mr={4}
                />
              </Box>
              <Box fontSize="2xl" fontWeight="bold">
                <h1>{user?.name}</h1>
                <h1>{education?.educationLevels?.[0]?.instituteName}</h1>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection={{ base: "column", lg: "row" }}
              justifyContent="space-between"
              alignItems={{ base: "flex-start", lg: "center" }}
              mb={4}
            >
              <Box
                flex="1"
                mr={4}
                display={"flex"}
                flexDirection={{ base: "row", lg: "column" }}
              >
                <Text display={"inline"}>Gender</Text>
                {isMobile && <>:</>}
                <Text display={"inline"} ml={{ base: "5px", lg: "0px" }}>
                  {user?.gender}
                </Text>
              </Box>
              <Box
                flex="1"
                mr={4}
                display={"flex"}
                flexDirection={{ base: "row", lg: "column" }}
              >
                <Text display={"inline"}>Mobile</Text>
                {isMobile && <>:</>}
                <Text display={"inline"} ml={{ base: "5px", lg: "0px" }}>
                  {user?.phoneNumber}
                </Text>
              </Box>
              <Box
                flex="1"
                display={"flex"}
                flexDirection={{ base: "row", lg: "column" }}
              >
                <Text display={"inline"}>Email ID</Text>
                {isMobile && <>:</>}
                <Text display={"inline"} ml={{ base: "5px", lg: "0px" }}>
                  {user?.email}
                </Text>
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              flexDirection={{ base: "column", lg: "row" }}
              flexWrap={"wrap"}
            >
              <Button
                as={Link}
                to="/dashboard/profile"
                bg="#FCB339"
                fontSize="xl"
                px={8}
                _hover={{ boxShadow: "0 4px 8px rgba(0,0,0,0.2)" }}
                transition="box-shadow 0.3s"
                mr="2"
                mt={{ base: "10px", lg: "5px" }}
              >
                Edit Profile
              </Button>
              {mentor && (
                <Button
                  as={Link}
                  to="/dashboard/verify-mentor"
                  bg="#FCB339"
                  fontSize="xl"
                  px={4}
                  _hover={{ boxShadow: "0 4px 8px rgba(0,0,0,0.2)" }}
                  transition="box-shadow 0.3s"
                  display={true ? "block" : "none"}
                  alignContent={"center"}
                  textAlign={"center"}
                  mt={{ base: "10px", lg: "5px" }}
                  mr="2"
                >
                  Verify Profile
                </Button>
              )}

              {mentee && (
                <Button
                  as={Link}
                  to="/dashboard/search"
                  bg="#FCB339"
                  fontSize="xl"
                  px={8}
                  mt={{ base: "10px", lg: "5px" }}
                  _hover={{ boxShadow: "0 4px 8px rgba(0,0,0,0.2)" }}
                  transition="box-shadow 0.3s"
                >
                  Search {mentor ? "Mentees" : "Mentors"}
                </Button>
              )}
            </Box>
          </Box>
          <Box
            flex="1"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            maxW={{ base: "100%", lg: "35%" }}
            marginLeft={"5%"}
            mt={{ base: "30px", lg: "0px" }}
          >
            <Box
              borderWidth="1px"
              shadow="md"
              borderRadius="20px"
              mb={4}
              display="flex"
              alignItems="center"
              justifyContent={"center"}
              fontSize="2xl"
              padding="10%"
              transition="transform 0.3s, box-shadow 0.3s"
              _hover={{
                transform: "scale(1.05)",
                boxShadow: "0 4px 8px rgba(0,0,0,0.3)",
              }}
            >
              <Box mr={4}>
                <i className="fa fa-calendar-alt" />
              </Box>
              <Link to="/dashboard/availability">
                <h1>Add My Availability</h1>
              </Link>
            </Box>
            <Box
              borderWidth="1px"
              shadow="md"
              fontSize="2xl"
              padding="10%"
              borderRadius="20px"
              display="flex"
              justifyContent={"center"}
              alignItems="center"
              transition="transform 0.3s, box-shadow 0.3s"
              _hover={{
                transform: "scale(1.05)",
                boxShadow: "0 4px 8px rgba(0,0,0,0.3)",
              }}
            >
              <Box mr={4}>
                <i className="fa fa-calendar" />
              </Box>
              <Link to="/dashboard/calendar">
                <h1>My Calendar</h1>
              </Link>
            </Box>
          </Box>
        </Box>
        {mentor && !mentor.isPaymentDone && (
          <Box
            display="flex"
            flexDirection="column"
            mt={{ base: "7%", lg: "2%" }}
            p="4"
            borderWidth="2px"
            borderRadius="md"
            boxShadow="xl"
            bgGradient="linear(to-r, teal.300, teal.500)"
          >
            <Flex
              alignItems="center"
              flexDir={"column"}
              justifyContent="center"
              mb="4"
            >
              <Heading as="h3" size={{ base: "md", lg: "lg" }} color="white">
                Submit your details to receive signup bonus price
              </Heading>
              <Heading as="h6" fontSize={"small"} color="blueviolet">
                Also please upload your college id card photo in verify profile
                section to receive money
              </Heading>
              <Heading as="h3" size={{ base: "sm", lg: "md" }} color="white">
                Disclaimer: due to high number of registration we will send your
                signup bonus in 3-5 days
              </Heading>
            </Flex>

            <Flex
              direction="column"
              fontSize={{ base: "md", lg: "xl" }}
              color="white"
              mb="4"
              mt="3%"
            >
              <Box mb="4">
                <Badge
                  colorScheme="yellow"
                  fontSize="1em"
                  px="2"
                  py="1"
                  borderRadius="md"
                >
                  Payment Method
                </Badge>
                <Select
                  placeholder="Select Payment Method"
                  mt="2"
                  bg="white"
                  color="black"
                  borderRadius="md"
                  value={paymentMethod}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                >
                  <option value="googlePay">Google Pay</option>
                  <option value="phonePe">PhonePe</option>
                  <option value="paytm">Paytm</option>
                </Select>
              </Box>

              <Box mb="4">
                <Badge
                  colorScheme="yellow"
                  fontSize="1em"
                  px="2"
                  py="1"
                  borderRadius="md"
                >
                  Phone Number (+91)
                </Badge>
                <Input
                  mt="2"
                  placeholder="Enter Phone Number"
                  bg="white"
                  color="black"
                  borderRadius="md"
                  type="tel"
                  value={mentorPaymentPhoneNumber}
                  onChange={(e) =>
                    handleMentorPaymentPhoneNumberChange(e.target.value)
                  }
                />
              </Box>

              <Flex
                justifyContent="space-between"
                mt="4"
                flexDirection={{ base: "column", lg: "row" }}
              >
                <Button
                  colorScheme="teal"
                  size={{ base: "sm", lg: "lg" }}
                  onClick={handleSubmitPaymentDetails}
                >
                  Submit Payment Details
                </Button>
                <Button
                  colorScheme="orange"
                  size={{ base: "sm", lg: "lg" }}
                  onClick={handleRemindOwner}
                  mt={{ base: "10px", lg: "0px" }}
                >
                  Remind Owner for My Payment
                </Button>
              </Flex>
            </Flex>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Hero;
