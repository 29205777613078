import React from "react";
import { GoogleLogin } from "@react-oauth/google";
import { Box, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { hitBeApi } from "../../apis/api";

const GoogleLoginComponent = () => {
  const toast = useToast();
  const navigate = useNavigate();

  // Function to handle Google login success
  const handleGoogleSuccess = async (credentialResponse) => {
    try {
      // Extract credential from Google response
      const tokenId = credentialResponse.credential;

      // Send tokenId to your backend for verification
      const response = await hitBeApi("user/login/google", "POST", {
        tokenId: tokenId,
      });

      if (response.success) {
        document.cookie = `token=${response?.data?.token}; path=/;`;
        navigate("/dashboard");
      } else {
        toast({
          title: "Login failed!",
          description: "User not found, please register.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error!",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Function to handle Google login failure
  const handleGoogleFailure = (error) => {
    console.error("Google login failed", error);
    toast({
      title: "Login failed!",
      description: error.message,
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  };

  return (
    <Box w="100%">
      <GoogleLogin
        w="100%"
        onSuccess={handleGoogleSuccess}
        onError={handleGoogleFailure}
        useOneTap // Optional: Adds One-Tap login
      />
    </Box>
  );
};

export default GoogleLoginComponent;
