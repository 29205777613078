import React, { useState } from "react";
import { Box, Button, Input, Select, Textarea } from "@chakra-ui/react";
import { formatDate2, makeReadable } from "../../utils/utils";

function DynamicForm({ object, objectName, handleUpdate }) {
  const [formData, setFormData] = useState(object);

  const handleChange = (key, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const handleSaveNewData = () => {
    handleUpdate({ [objectName]: formData });
  };

  const handleArrayFieldChange = (index, fieldKey, fieldValue) => {
    setFormData((prevData) => {
      const newArray = [...prevData[fieldKey]];
      newArray[index] = fieldValue;
      return {
        ...prevData,
        [fieldKey]: newArray,
      };
    });
  };

  const notAllowedFields = [
    "_id",
    "__v",
    "userId",
    "createdAt",
    "updatedAt",
    "isBlocked",
    "isVerified",
    "verifyOtp",
    "otpExpiryTimestamp",
    "phoneNumber",
    "email",
    "latestPaymentDate",
    "planExpiryDate",
    "paymentDate",
    "paymentIds",
    "totalMeetingsScheduledCount",
    "maxMeetingsScheduleLimit",
    "totalConversationsCreatedCount",
    "maxConversationsCreationLimit",
    "paymentPlans",
    "isPaymentDone",
    "lastPaymentReminderSent",
    "voucherType",
    "voucherCode",
    "mentorId",
    "imageUrl",
    "adminMentorsLastReminderSentDate",
  ];

  return (
    <Box>
      <Box fontSize={{ base: "2xl", lg: "4xl" }} fontWeight={"extrabold"}>
        {object !== undefined &&
          object !== null &&
          Object.keys(object).length !== 0 && (
            <h1>
              Edit
              {makeReadable(
                objectName[0].toUpperCase() + objectName.slice(1)
              )}{" "}
              Details
            </h1>
          )}
      </Box>
      {formData !== undefined &&
        formData !== null &&
        Object.keys(object).length !== 0 && (
          <Box
            display={"flex"}
            flexDirection={"row"}
            flexWrap={"wrap"}
            alignContent={"flex-end"}
            alignItems={"flex-end"}
          >
            {Object.keys(formData).map((key) => (
              <Box key={key}>
                {notAllowedFields.indexOf(key) === -1 && (
                  <Box margin={"20px"}>
                    <Box fontSize={"xl"} fontWeight={"medium"}>
                      {!Array.isArray(formData[key]) && (
                        <h1>
                          {makeReadable(key[0].toUpperCase() + key.slice(1))}
                        </h1>
                      )}
                    </Box>
                    {Array.isArray(formData[key]) ? (
                      typeof formData[key][0] === "string" ? (
                        <Input
                          type="text"
                          value={formData[key]}
                          onChange={(e) => handleChange(key, e.target.value)}
                        />
                      ) : (
                        formData[key].map((item, index) => (
                          <Box
                            key={index}
                            display={"flex"}
                            flexDirection={"row"}
                            flexWrap={"wrap"}
                          >
                            {Object.keys(item).map((fieldKey) => (
                              <Box key={fieldKey}>
                                {fieldKey !== "_id" && (
                                  <Box marginRight={"20px"}>
                                    <Box fontSize={"xl"} fontWeight={"medium"}>
                                      <h1>
                                        {makeReadable(
                                          fieldKey[0].toUpperCase() +
                                            fieldKey.slice(1)
                                        )}
                                      </h1>
                                    </Box>
                                    <Input
                                      type="text"
                                      value={item[fieldKey]}
                                      onChange={(e) =>
                                        handleArrayFieldChange(index, key, {
                                          ...item,
                                          [fieldKey]: e.target.value,
                                        })
                                      }
                                    />
                                  </Box>
                                )}
                              </Box>
                            ))}
                          </Box>
                        ))
                      )
                    ) : typeof formData[key] === "string" ? (
                      <Input
                        type="text"
                        value={
                          key === "createdAt" || key === "updatedAt"
                            ? formatDate2(formData[key])
                            : formData[key]
                        }
                        onChange={(e) => handleChange(key, e.target.value)}
                      />
                    ) : typeof formData[key] === "number" ? (
                      <Input
                        type="number"
                        value={formData[key]}
                        onChange={(e) => handleChange(key, e.target.value)}
                      />
                    ) : typeof formData[key] === "boolean" ? (
                      <Select
                        value={formData[key] ? "true" : "false"}
                        onChange={(e) =>
                          handleChange(key, e.target.value === "true")
                        }
                      >
                        <option value="true">True</option>
                        <option value="false">False</option>
                      </Select>
                    ) : Array.isArray(formData[key]) ? (
                      <Select
                        value={formData[key]}
                        onChange={(e) => handleChange(key, e.target.value)}
                      >
                        {formData[key].map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </Select>
                    ) : (
                      <Textarea
                        value={formData[key]}
                        onChange={(e) => handleChange(key, e.target.value)}
                      />
                    )}
                  </Box>
                )}
              </Box>
            ))}
            <Box
              width={{ base: "100%", lg: "40%" }}
              display={"flex"}
              flexDirection={"row"}
              alignContent={"flex-end"}
              alignItems={"flex-end"}
              margin={"20px"}
            >
              <Button onClick={handleSaveNewData}>
                Save{" "}
                {makeReadable(
                  objectName[0].toUpperCase() + objectName.slice(1)
                )}
              </Button>
            </Box>
          </Box>
        )}
    </Box>
  );
}

export default DynamicForm;
