import React, { useState } from "react";
import {
  Box,
  Heading,
  Text,
  ListItem,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";
import Header from "../common/Header";
import Navbar from "../common/Navbar";
import Footer from "../common/Footer";

const JeeAndBoardsPrep = () => {
  const [openNavbar, setOpenNavbar] = useState(false);

  return (
    <Box>
      <Box textAlign={"center"} opacity={openNavbar ? "0.1" : "1"}>
        <Header openNavbar={openNavbar} setOpenNavbar={setOpenNavbar}></Header>
      </Box>
      <Box>
        <Navbar openNavbar={openNavbar} setOpenNavbar={setOpenNavbar}></Navbar>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        padding={"5%"}
        paddingBottom={{ base: "0%", lg: "5%" }}
        paddingTop={{ base: "40%", lg: "10%" }}
        marginBottom={{ base: "0%", lg: "0%" }}
        opacity={openNavbar ? "0.1" : "1"}
        onClick={() => setOpenNavbar(false)}
      >
        <Box as="section" p={6} maxW="800px" mx="auto">
          <Heading as="h1" size="2xl" mb={4} textTransform="uppercase">
            How to Effectively Balance JEE and Board Exam Preparation
          </Heading>

          <Text fontSize="lg" mb={4}>
            Preparing for both JEE and board exams is a crucial task for
            aspiring engineers. While JEE opens doors to top engineering
            institutions, board marks play a vital role in determining
            eligibility for JEE Advanced. Candidates need to secure a minimum
            percentage in their 12th-grade exams alongside being in the top 20
            percentile of their respective boards.
          </Text>

          <Text fontSize="md" mb={4}>
            <strong>Minimum board percentage requirements:</strong>
          </Text>
          <UnorderedList mb={4} fontSize="md">
            <ListItem>General category: 75%</ListItem>
            <ListItem>SC/ST/PwD category: 65%</ListItem>
          </UnorderedList>

          <Text fontSize="lg" mb={4}>
            To ace both exams, students need a well-structured strategy. While
            board exams focus on theoretical knowledge, JEE challenges you with
            problem-solving and analytical skills. Striking a balance between
            both is essential for success.
          </Text>

          <VStack align="start" spacing={6}>
            <Box>
              <Heading as="h2" size="lg" mb={2}>
                Core Concepts to Master
              </Heading>
              <Text fontSize="md">
                JEE has a broader syllabus compared to board exams, but both
                share some foundational topics. Building a strong base using
                NCERT books will benefit you in both exams. Focus on
                understanding concepts thoroughly before jumping into solving
                problems. Review topics you find challenging and keep revising.
              </Text>
            </Box>

            <Box>
              <Heading as="h2" size="lg" mb={2}>
                Tackling Numerical Problems
              </Heading>
              <Text fontSize="md">
                Physics and Mathematics in JEE require a solid grip on numerical
                problem-solving. Start by understanding the concept, then
                practice solving a wide range of problems. Avoid rushing through
                questions, as this often leads to mistakes. Consistent practice
                will improve your speed and accuracy for both board and JEE
                exams.
              </Text>
            </Box>

            <Box>
              <Heading as="h2" size="lg" mb={2}>
                The Role of Mock Tests
              </Heading>
              <Text fontSize="md">
                Mock tests are a powerful tool for assessing your preparation.
                Take mock tests for both board and JEE exams to get familiar
                with their patterns and time management. Focus particularly on
                JEE mock tests, as they involve more challenging questions.
                Practice integer-type questions regularly since they don’t carry
                negative marking in JEE.
              </Text>
            </Box>

            <Box>
              <Heading as="h2" size="lg" mb={2}>
                Daily Revision Routine
              </Heading>
              <Text fontSize="md">
                Regular revision is key to long-term retention. Instead of
                waiting until the syllabus is complete, integrate daily or
                weekly revision into your schedule. Pay special attention to
                subjects like Inorganic Chemistry, which require more
                memorization. After each mock test, review your mistakes and
                revise those topics to avoid repeating errors.
              </Text>
            </Box>

            <Box>
              <Heading as="h2" size="lg" mb={2}>
                Syncing Your Preparation
              </Heading>
              <Text fontSize="md">
                Keep your JEE and board preparation in sync to avoid confusion
                and save time. Start with common topics that overlap between the
                two exams, then dive deeper into more complex JEE topics. Taking
                this step-by-step approach will help you cover both syllabuses
                effectively.
              </Text>
            </Box>

            <Box>
              <Heading as="h2" size="lg" mb={2}>
                3:1 to 1:3 Time Management Strategy
              </Heading>
              <Text fontSize="md">
                A smart time management approach is crucial when juggling both
                exams. Begin by dedicating 75% of your time to board preparation
                and 25% to JEE. As you progress, shift your focus gradually
                until you’re spending 75% of your time on JEE preparation. This
                allows you to strengthen theoretical knowledge early on and
                focus on problem-solving later.
              </Text>
            </Box>
          </VStack>

          <Text fontSize="lg" mt={6}>
            Need more personalized guidance?{" "}
            <strong>
              <a
                href="/mentee-registration"
                style={{ color: "blue", textDecoration: "underline" }}
              >
                Sign up now
              </a>
            </strong>{" "}
            to schedule a call with a mentor for JEE and board exams
            preparation. Experience tailored mentorship at{" "}
            <a
              href="https://menteementors.com"
              style={{ color: "blue", textDecoration: "underline" }}
            >
              MenteeMentors
            </a>{" "}
            and take the first step towards your success!
          </Text>
        </Box>
      </Box>
      <Box
        textAlign={"center"}
        opacity={openNavbar ? "0.1" : "1"}
        onClick={() => setOpenNavbar(false)}
      >
        <Footer></Footer>
      </Box>
    </Box>
  );
};

export default JeeAndBoardsPrep;
