import {
  Box,
  Button,
  Img,
  Input,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import Header from "../common/Header";
import Navbar from "../common/Navbar";
import Footer from "../common/Footer";
import { hitBeApi } from "../../apis/api";

const supports = [
  {
    intro:
      "Comprehensive support for students in their IIT JEE and NEET journey, from A to Z.",
    image:
      "https://images.pexels.com/photos/3036405/pexels-photo-3036405.jpeg?auto=compress&cs=tinysrgb&w=600",
  },
  {
    intro:
      "Get help whenever you need it, with best mentors available 24/7 to answer all your questions.",
    image:
      "https://images.pexels.com/photos/3755440/pexels-photo-3755440.jpeg?auto=compress&cs=tinysrgb&w=600",
  },
  {
    intro:
      "Benefit from the wisdom of recent exam toppers to guide you through your preparation journey.",
    image:
      "https://images.pexels.com/photos/3769714/pexels-photo-3769714.jpeg?auto=compress&cs=tinysrgb&w=600",
  },
];

function ContactUs() {
  const [openNavbar, setOpenNavbar] = useState(false);
  const [formDetails, setFormDetails] = useState({
    fullName: "",
    subject: "",
    email: "",
    mobileNumber: "",
    message: "",
  });
  const toast = useToast();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^[0-9]{10}$/;
    return phoneRegex.test(phoneNumber);
  };

  const handleFormSubmit = async () => {
    if (!formDetails.email && !formDetails.mobileNumber) {
      toast({
        title: "Error",
        description: "Email and mobile number both cannot be empty",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (formDetails.email && !validateEmail(formDetails.email)) {
      toast({
        title: "Error",
        description: "Invalid email format",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (
      formDetails.mobileNumber &&
      !validatePhoneNumber(formDetails.mobileNumber)
    ) {
      toast({
        title: "Error",
        description: "Invalid mobile number format",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      await hitBeApi("contact-us", "POST", {
        name: formDetails.fullName,
        email: formDetails.email,
        phoneNumber: formDetails.mobileNumber,
        subject: formDetails.subject,
        message: formDetails.message,
      });
      toast({
        title: "Success",
        description: "Your message has been sent successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setFormDetails({
        fullName: "",
        subject: "",
        email: "",
        mobileNumber: "",
        message: "",
      });
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      <Box textAlign="center" opacity={openNavbar ? "0.1" : "1"}>
        <Header openNavbar={openNavbar} setOpenNavbar={setOpenNavbar} />
      </Box>
      <Box>
        <Navbar openNavbar={openNavbar} setOpenNavbar={setOpenNavbar} />
      </Box>
      <Box
        position="relative"
        top={{ base: "70px", lg: "110px" }}
        p="5% 3%"
        opacity={openNavbar ? "0.1" : "1"}
        onClick={() => setOpenNavbar(false)}
      >
        <Box
          display="flex"
          flexDirection={{ base: "column", lg: "row" }}
          alignItems="flex-start"
        >
          <Box
            mr={{ base: "0px", lg: "100px" }}
            mt="100px"
            maxW={{ base: "100%", lg: "40%" }}
          >
            <Box fontSize={{ base: "2xl", lg: "4xl" }} fontWeight="bold">
              <h1>We love to hear from you</h1>
            </Box>
            <Box fontSize={{ base: "medium", lg: "xl" }} fontWeight="normal">
              <h1>
                Share your feedback, ask a question or just drop a Hi! We are
                here for you.
              </h1>
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignContent={"center"}
            border="0px"
            shadow="0px 0px 20px 0px rgba(0, 0, 0, 0.5)"
            borderRadius="50px"
            w={{ base: "100%", lg: "60%" }}
            mt={{ base: "15%", lg: "0%" }}
            maxW={{ base: "100%", lg: "40%" }}
          >
            <Box
              textAlign="center"
              fontWeight="extrabold"
              fontSize="2xl"
              borderBottom="1px solid black"
              p="10px 20px"
              bg="#C5FBE3"
              borderTopRadius="50px"
            >
              <h1>Let's get in touch!</h1>
            </Box>
            <Box
              display="flex"
              flexDirection={{ base: "column", lg: "row" }}
              p={{ base: "5px 20px", lg: "20px 40px" }}
              paddingTop={{ base: "20px", lg: "50px" }}
            >
              <Box
                fontWeight="extrabold"
                fontSize={{ base: "medium", lg: "xl" }}
                mr={{ base: "0px", lg: "50px" }}
              >
                <h1>Full Name</h1>
                <Input
                  name="fullName"
                  value={formDetails.fullName}
                  required
                  onChange={handleInputChange}
                />
              </Box>
              <Box
                fontWeight="extrabold"
                fontSize={{ base: "medium", lg: "xl" }}
              >
                <h1>Subject</h1>
                <Input
                  name="subject"
                  value={formDetails.subject}
                  required
                  onChange={handleInputChange}
                />
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection={{ base: "column", lg: "row" }}
              p={{ base: "5px 40px", lg: "20px 40px" }}
            >
              <Box
                fontWeight="extrabold"
                fontSize={{ base: "medium", lg: "xl" }}
                mr={{ base: "0px", lg: "50px" }}
              >
                <h1>Email</h1>
                <Input
                  type="email"
                  name="email"
                  value={formDetails.email}
                  required
                  onChange={handleInputChange}
                />
              </Box>
              <Box
                fontWeight="extrabold"
                fontSize={{ base: "medium", lg: "xl" }}
                mt={{ base: "20px", lg: "0px" }}
              >
                <h1>Mobile Number</h1>
                <Input
                  type="tel"
                  name="mobileNumber"
                  value={formDetails.mobileNumber}
                  required
                  onChange={handleInputChange}
                />
              </Box>
            </Box>
            <Box
              fontWeight="extrabold"
              fontSize={{ base: "medium", lg: "xl" }}
              p={{ base: "5px 40px", lg: "20px 40px" }}
              maxW={{ base: "100%", lg: "100%" }}
            >
              <h1>Message</h1>
              <Textarea
                name="message"
                value={formDetails.message}
                onChange={handleInputChange}
              />
            </Box>
            <Box p="20px 40px" pb="50px">
              <Button
                w="100%"
                fontSize="3xl"
                p="30px 50px"
                bg="#FCC343"
                _hover={{ bg: "#FCC343" }}
                onClick={handleFormSubmit}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection={{ base: "column", lg: "row" }}
          mt="10%"
          mb="15%"
          justifyContent="space-between"
        >
          {supports.map((support, index) => (
            <Box
              key={index}
              maxW={{ base: "100%", lg: "30%" }}
              textAlign="center"
              mb={{ base: "20px", lg: "0" }}
              p={{ base: "10px", lg: "20px" }}
              bg="white"
              borderRadius="20px"
              boxShadow="0px 10px 15px rgba(0, 0, 0, 0.1)"
              transition="transform 0.3s, box-shadow 0.3s"
              _hover={{
                transform: "scale(1.05)",
                boxShadow: "0px 15px 25px rgba(0, 0, 0, 0.2)",
              }}
            >
              <Img
                src={support.image}
                alt="Support"
                borderRadius="20px"
                mb="20px"
                boxShadow="0px 5px 10px rgba(0, 0, 0, 0.1)"
                transition="box-shadow 0.3s"
                _hover={{
                  boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
                }}
              />
              <Text
                fontSize={{ base: "xl", lg: "2xl" }}
                fontWeight="bold"
                color="gray.700"
                mb="10px"
                transition="color 0.3s"
                _hover={{ color: "blue.500" }}
              >
                {support.intro}
              </Text>
            </Box>
          ))}
        </Box>
      </Box>
      <Footer />
    </Box>
  );
}

export default ContactUs;
