import { Box, useColorModeValue } from "@chakra-ui/react";
import React from "react";

function MentorshipFeature(props) {
  const boxBg = useColorModeValue("white", "gray.800");
  const headingColor = useColorModeValue("teal.600", "teal.300");
  const shadowColor = useColorModeValue(
    "rgba(0, 0, 0, 0.1)",
    "rgba(255, 255, 255, 0.1)"
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      padding="30px"
      background={boxBg}
      borderRadius="20px"
      maxWidth={{ base: "100%", lg: "30%" }}
      marginRight={{ base: "0%", lg: "3%" }}
      marginTop={{ base: "10%", lg: "2%" }}
      transition="transform 0.3s, box-shadow 0.3s"
      _hover={{
        transform: "translateY(-5px)",
        boxShadow: `0 10px 25px ${shadowColor}`,
      }}
      boxShadow={`0 5px 15px ${shadowColor}`}
    >
      <Box
        fontSize={{ base: "lg", lg: "2xl" }}
        fontWeight="bold"
        color={headingColor}
        textAlign="left"
        mb="3%"
        borderBottom="2px solid"
        borderColor={headingColor}
        pb="2%"
      >
        {props.feature.heading}
      </Box>
      <Box fontSize={{ base: "sm", lg: "md" }} textAlign="left" mt="5%">
        {props.feature.content}
      </Box>
    </Box>
  );
}

export default MentorshipFeature;
