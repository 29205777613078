import { Box, Button, Image, useBreakpointValue } from "@chakra-ui/react";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

function Header(props) {
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const navigate = useNavigate();
  const handleClickOnLogin = () => {
    navigate("/login");
  };
  return (
    <Box
      display={"flex"}
      flexDirection={{ base: "column", lg: "row" }}
      justifyContent={"space-between"}
      alignItems={"center"}
      shadow={"0px 0px 20px 0px rgba(0, 0, 0, 0.12)"}
      position={"fixed"}
      width={"100%"}
      zIndex={"200"}
      padding={"1%"}
      backgroundColor={"#FFFFFF"}
    >
      <Box
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        w={isMobile && "90%"}
      >
        <Box
          fontSize={{ base: "2xl", lg: "3xl" }}
          fontWeight={"extrabold"}
          marginLeft={"10px"}
        >
          <Link to="/">
            <Image
              height={"30px"}
              src="/logo.png"
              alt="logo"
              mr="5px"
              display={"inline"}
            ></Image>
            MenteeMentors
          </Link>
        </Box>
        {isMobile && (
          <Button
            backgroundColor={"#FFFFFF"}
            fontSize={"3xl"}
            _hover={{ bg: "#green.300" }}
            onClick={() => props.setOpenNavbar(true)}
          >
            <i className="fa fa-solid fa-bars"></i>
          </Button>
        )}
      </Box>
      <Box
        minWidth={{ base: "90%", lg: "60%" }}
        textAlign={"right"}
        display={"flex"}
        marginBottom={{ base: "5%", lg: "0%" }}
        flexDirection={{ base: "column", lg: "row" }}
        justifyContent={"space-around"}
      >
        {!isMobile && (
          <Button
            margin={"0% 2%"}
            backgroundColor={"#JJJKKK"}
            borderRadius={"full"}
            _hover={{ bg: "green.300" }}
            fontWeight={"extrabold"}
            paddingLeft={{ base: "10%", lg: "2%" }}
            paddingRight={{ base: "10%", lg: "2%" }}
            marginTop={{ base: "20px", lg: "0px" }}
          >
            <Link to="/mentee-registration">
              Chat/Schedule meeting With Mentors
            </Link>
          </Button>
        )}

        {!isMobile && (
          <Button
            margin={"0% 1%"}
            backgroundColor={"#FFFFFF"}
            _hover={{ bg: "green.300", opacity: "2", zIndex: "4" }}
            fontSize={"xl"}
            fontWeight={"medium"}
            marginRight={{ base: "0px", lg: "20px" }}
            marginTop={{ base: "20px", lg: "0px" }}
          >
            <Link to="/become-mentor">Become A Mentor</Link>
          </Button>
        )}

        <Button
          margin={"0% 1%"}
          backgroundColor="#FFFFFF"
          _hover={{ bg: "green.300" }}
          border={"1px solid #28A745"}
          marginTop={{ base: "20px", lg: "0px" }}
          onClick={handleClickOnLogin}
        >
          <Link to="/login">Login</Link>
        </Button>

        {!isMobile && (
          <Button
            backgroundColor={"#FFFFFF"}
            fontSize={"3xl"}
            _hover={{ bg: "#green.300" }}
            onClick={() => props.setOpenNavbar(true)}
          >
            <i className="fa fa-solid fa-bars"></i>
          </Button>
        )}
      </Box>
    </Box>
  );
}

export default Header;
