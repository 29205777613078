import { serverBaseUrl } from "../constants/constants";
import { getCookieToken } from "../utils/utils";

export async function hitBeApi(endpoint, method = "GET", body = null) {
  try {
    const token = getCookieToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const response = await fetch(`${serverBaseUrl}/${endpoint}`, {
      method: method,
      headers: headers,
      body: body ? JSON.stringify(body) : null,
    });

    if (!response.ok) {
      // Parse the error response
      const errorResponse = await response.json();
      throw new Error(errorResponse.message.substring(0, 80));
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}

export async function hitBeApiWithHeaders(
  endpoint,
  method = "GET",
  body = null,
  headers = null
) {
  try {
    const token = getCookieToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const response = await fetch(`${serverBaseUrl}/${endpoint}`, {
      method: method,
      headers: headers,
      body: body ? JSON.stringify(body) : null,
    });

    if (!response.ok) {
      // Parse the error response
      const errorResponse = await response.json();
      throw new Error(errorResponse.message.substring(0, 80));
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}
