import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Divider,
  FormLabel,
  Image,
  Input,
  Link,
  Select,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { hitBeApi } from "../../apis/api";
import Header from "../common/Header";
import Footer from "../common/Footer";
import Navbar from "../common/Navbar";
import { useDispatch } from "react-redux";

function MenteeSignup() {
  const [openNavbar, setOpenNavbar] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const belongsToRef = useRef(null);
  const dispatch = useDispatch();

  const countryCodes = [
    { code: "+1", name: "USA" },
    { code: "+91", name: "India" },
    { code: "+81", name: "Japan" },
    { code: "+65", name: "Singapore" },
    { code: "+49", name: "Germany" },
    { code: "+33", name: "France" },
    { code: "+39", name: "Italy" },
    { code: "+31", name: "Netherlands" },
    { code: "+41", name: "Switzerland" },
    { code: "+44", name: "UK" },
  ];

  const [formData, setFormData] = useState({
    belongsTo: "",
    name: "",
    contactNumber: "",
    countryCode: "+91",
    gender: "",
    helpNeeded: "",
    email: "",
    state: "",
    city: "",
    examType: "",
    classStandard: "",
    languages: "",
  });

  const [isOtpSent, setIsOtpSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [isOtpBeingSent, setIsOtpBeingSent] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "contactNumber") {
      if (value.length > 10 || isNaN(value)) {
        toast({
          title: "Error",
          description: "Please enter a valid phone number.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }
    }

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(formData.email)) {
      toast({
        title: "Error",
        description: "Please enter a valid email address.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return false;
    }

    const phonePattern = /^[6-9]\d{9}$/;
    if (!phonePattern.test(formData.contactNumber)) {
      toast({
        title: "Error",
        description:
          "Please enter a valid 10-digit Indian phone number starting with 6-9.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return false;
    }

    for (const key in formData) {
      if (formData[key] === "") {
        toast({
          title: "Error",
          description: `Please fill out the ${key} field.`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    if (isOtpBeingSent) {
      return;
    }

    try {
      const fullContactNumber = `${formData.countryCode}${formData.contactNumber}`;

      setIsOtpBeingSent(true);
      // Continue to create the mentee after OTP verification
      await hitBeApi("mentee", "POST", {
        ...formData,
        contactNumber: fullContactNumber,
      });

      // Send OTP to both email and phone
      await hitBeApi("phone/otp", "POST", { phoneNumber: fullContactNumber });

      // Replace the form box with OTP verification box
      setIsOtpSent(true);
      setIsOtpBeingSent(false);

      toast({
        title: "OTP Sent",
        description:
          "OTP has been sent to your email and phone number. Please verify.",
        status: "info",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      setIsOtpBeingSent(false);
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleOtpVerification = async () => {
    try {
      const { contactNumber } = formData;
      const fullContactNumber = `${formData.countryCode}${contactNumber}`;
      const response = await hitBeApi(`phone/otp/verify`, "POST", {
        phoneNumber: fullContactNumber,
        otp: parseInt(otp),
      });

      if (response.success) {
        document.cookie = `token=${response?.data?.token}; path=/;`;
        navigate("/dashboard");
        dispatch({
          type: "Set_Login_Phone_Number",
          value: fullContactNumber,
        });
        toast({
          title: "Success",
          description: "Mentee signup successful!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: "OTP verification failed. Please try login.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      isOtpSent ? handleOtpVerification() : handleSubmit(e);
    }
  };

  useEffect(() => {
    if (belongsToRef.current) {
      belongsToRef.current.focus();
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOtpSent]);

  return (
    <Box>
      <Box textAlign={"center"} opacity={openNavbar ? "0.1" : "1"}>
        <Header openNavbar={openNavbar} setOpenNavbar={setOpenNavbar} />
      </Box>
      <Box>
        <Navbar openNavbar={openNavbar} setOpenNavbar={setOpenNavbar} />
      </Box>
      <Box
        paddingTop={{ base: "0%", lg: "7%" }}
        display={"flex"}
        flexDirection={{ base: "column", lg: "row" }}
        justifyContent={"center"}
        opacity={openNavbar ? "0.1" : "1"}
        onClick={() => setOpenNavbar(false)}
        borderRadius={"20px"}
        mx={{ base: "5%", lg: "5%" }}
        paddingBottom={{ base: "0%", lg: "5%" }}
      >
        <Box
          display={"flex"}
          marginTop={{ base: "50%", lg: "0%" }}
          paddingTop={{ base: "20px", lg: "0px" }}
          flexDirection={{ base: "column", lg: "row" }}
          justifyContent={"center"}
          alignContent={"center"}
          alignItems={"center"}
          border={"10px"}
          shadow={"0px 0px 20px 0px rgba(0, 0, 0, 0.5)"}
          width={"100%"}
          paddingBottom={"0%"}
          px="5%"
          borderRadius={"20px"}
        >
          <Image
            src="https://images.pexels.com/photos/4145037/pexels-photo-4145037.jpeg?auto=compress&cs=tinysrgb&w=800"
            alt="group"
            height={{ base: "500px", lg: "90%" }}
            width={{ base: "100%", lg: "60%" }}
            borderRadius={"30px"}
          />
          <Box
            paddingLeft={{ base: "0", lg: "5%" }}
            minWidth={"40%"}
            id="otp-box"
            marginTop={{ base: "10%", lg: "0%" }}
          >
            {!isOtpSent ? (
              <>
                <Box
                  fontWeight={"extrabold"}
                  fontSize={"4xl"}
                  textAlign={{ base: "center", lg: "left" }}
                >
                  <Text fontSize={"md"} marginTop={"10px"}>
                    <Link
                      href="/login"
                      color="blue.500"
                      textDecoration="underline"
                      _hover={{ color: "blue.700" }}
                    >
                      Login
                    </Link>{" "}
                    if already signed up
                  </Text>
                </Box>

                {/* Divider */}
                <Divider
                  orientation="horizontal"
                  my={4}
                  borderColor="gray.300"
                />

                <Box
                  fontSize={{ base: "md", lg: "2xl" }}
                  textAlign={{ base: "center", lg: "left" }}
                  mt={"10px"}
                  fontWeight={"bold"}
                >
                  <h1>Signup as Student/Parent on MenteeMentors</h1>
                </Box>
                <Box my={"10px"} py="10px">
                  <Select
                    key={"belongsTo"}
                    placeholder={`You are a`}
                    required
                    name={"belongsTo"}
                    onChange={handleChange}
                    ref={belongsToRef}
                  >
                    {["Student", "Parent"].map((value, index) => (
                      <option value={value} key={index}>
                        {value}
                      </option>
                    ))}
                  </Select>
                  <FormLabel fontWeight={"semibold"}>Name</FormLabel>
                  <Input
                    placeholder="Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                  <FormLabel fontWeight={"semibold"}>Contact Number</FormLabel>
                  <Box display={"flex"}>
                    <Select
                      name="countryCode"
                      value={formData.countryCode}
                      onChange={handleChange}
                      width="50%"
                      mr="2"
                    >
                      {countryCodes.map((code, index) => (
                        <option key={index} value={code.code}>
                          {code.name} ({code.code})
                        </option>
                      ))}
                    </Select>
                    <Input
                      placeholder="Contact Number"
                      name="contactNumber"
                      value={formData.contactNumber}
                      onChange={handleChange}
                    />
                  </Box>
                  <FormLabel fontWeight={"semibold"}>
                    Student's Gender
                  </FormLabel>
                  <Select
                    placeholder={`Select your gender`}
                    name="gender"
                    value={formData.gender}
                    onChange={handleChange}
                  >
                    {["Male", "Female", "Others"].map((value, index) => (
                      <option value={value} key={index}>
                        {value}
                      </option>
                    ))}
                  </Select>
                  <FormLabel fontWeight={"semibold"}>Your Email</FormLabel>
                  <Input
                    placeholder="Email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  <FormLabel fontWeight={"semibold"}>Your Home State</FormLabel>
                  <Input
                    placeholder="State"
                    name="state"
                    value={formData.state}
                    onChange={handleChange}
                  />
                  <FormLabel fontWeight={"semibold"}>Your Home City</FormLabel>
                  <Input
                    placeholder="City"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                  />
                  <FormLabel fontWeight={"semibold"}>
                    Which Exam student is preparing for?
                  </FormLabel>
                  <Select
                    placeholder="Select exam type"
                    name="examType"
                    value={formData.examType}
                    onChange={handleChange}
                  >
                    <option value="IIT-JEE">IIT-JEE</option>
                    <option value="NEET">NEET</option>
                  </Select>
                  <FormLabel fontWeight={"semibold"}>
                    Student's Class/Standard
                  </FormLabel>
                  <Input
                    placeholder="e.g., 10th or 12th"
                    name="classStandard"
                    value={formData.classStandard}
                    onChange={handleChange}
                  />
                  <FormLabel fontWeight={"semibold"}>
                    What help student need ?
                  </FormLabel>
                  <Input
                    placeholder="e.g., I need help in I am not able to score marks in physics"
                    name="helpNeeded"
                    value={formData.helpNeeded}
                    onChange={handleChange}
                  />
                  <FormLabel fontWeight={"semibold"}>
                    Preferred Language
                  </FormLabel>
                  <Input
                    placeholder="e.g., Hindi, English"
                    name="languages"
                    value={formData.languages}
                    onChange={handleChange}
                  />
                </Box>
                <Button
                  bg={"blue.500"}
                  color={"white"}
                  width={"100%"}
                  _hover={{ bg: "blue.600" }}
                  marginTop={"10px"}
                  onClick={handleSubmit}
                  mb={{ base: "20px", lg: "0px" }}
                >
                  {isOtpBeingSent ? (
                    <Spinner size={"sm"}></Spinner>
                  ) : (
                    "Continue"
                  )}
                </Button>
              </>
            ) : (
              <>
                <Box
                  fontWeight={"extrabold"}
                  fontSize={"4xl"}
                  textAlign={{ base: "center", lg: "left" }}
                >
                  <h1>Verify OTP</h1>
                </Box>
                <Box
                  fontSize={{ base: "md", lg: "2xl" }}
                  textAlign={{ base: "center", lg: "left" }}
                >
                  <h1>OTP has been sent to your phone number</h1>
                </Box>
                <Input
                  placeholder="Enter OTP"
                  name="otp"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  marginTop={"10px"}
                />
                <Button
                  bg={"green.500"}
                  color={"white"}
                  width={"100%"}
                  _hover={{ bg: "green.600" }}
                  marginTop={"10px"}
                  onClick={handleOtpVerification}
                  marginBottom={"5%"}
                >
                  Verify OTP
                </Button>
              </>
            )}
          </Box>
        </Box>
      </Box>
      <Box>
        <Footer />
      </Box>
    </Box>
  );
}

export default MenteeSignup;
