import React from "react";
import { Box, Text, Flex, useColorModeValue } from "@chakra-ui/react";
import { convertTo12HourFormat } from "../../utils/utils";

const MessageBox = ({ message, user, conversation }) => {
  const bgColor = message?.senderId === user._id ? "#D9FDD2" : "#FFFFFF";
  const textColor = useColorModeValue("black", "white");

  const renderContent = () => {
    const isCloudinaryUrl = message.content.includes(
      "https://res.cloudinary.com"
    );

    const isGoogleUrl = message.content.includes("https://drive.google.com");

    const isAwsUrl = message.content.includes("https://mystartupdatabucket");

    if (isCloudinaryUrl) {
      return (
        <a
          href={message.content}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "blue" }}
        >
          {" file url"}
        </a>
      );
    } else {
      if (isGoogleUrl) {
        return (
          <a
            href={message.content}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "blue" }}
          >
            {message.contentMetaData || " file url"}
          </a>
        );
      } else {
        if (isAwsUrl) {
          return (
            <a
              href={message.content}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "blue" }}
            >
              {message.contentMetaData || " file url"}
            </a>
          );
        }
        return (
          <Text fontSize={{ base: "small", lg: "medium" }} maxW={"60vh"}>
            {message.content}
          </Text>
        );
      }
    }
  };

  return (
    <Box
      bg={bgColor}
      color={textColor}
      p={2}
      rounded="lg"
      shadow="sm"
      my={1}
      fontSize={{ base: "xx-small", lg: "small" }}
    >
      <Flex
        justifyContent="space-between"
        alignItems={"center"}
        alignContent={"center"}
      >
        <Box>{renderContent()}</Box>
        <Text
          color="gray.500"
          ml="20px"
          fontSize={{ base: "xx-small", lg: "small" }}
        >
          {convertTo12HourFormat(message.timestamp)}
        </Text>
      </Flex>
    </Box>
  );
};

export default MessageBox;
