import { Box, Button, useDisclosure } from "@chakra-ui/react";
import React, { useState } from "react";
import Question from "../utils/Question";
import Header from "../common/Header";
import Navbar from "../common/Navbar";
import Footer from "../common/Footer";

const faqs = [
  {
    question: "Can long-term mentorship significantly impact my preparation?",
    answer:
      "Absolutely! Long-term mentorship provides continuous, personalized support, which can lead to better outcomes compared to traditional courses. Our mentors' stats showcase the success of this approach.",
  },
  {
    question: "How long should my mentorship last?",
    answer:
      "The duration of your mentorship depends on your specific goals and current situation.",
  },
  {
    question: "How many sessions are included in long-term mentorship ?",
    answer:
      "You can find all plan from our mentorship plans page (menteementors.com/mentorship-plans) .",
  },
  {
    question: "When is the best time to start long-term mentorship?",
    answer:
      "Starting mentorship early can help avoid common mistakes and ensure efficient progress, whether you're a beginner, intermediate, or advanced learner. Longer preparation periods with a mentor yield the best results.",
  },
  {
    question: "What is the money-back guarantee and mentor-change policy?",
    answer:
      "We offer a 100% money-back guarantee within the first 7 days, no questions asked. If you're not satisfied, you can get a full refund. Our mentors are dedicated professionals who prioritize community support over earnings.",
  },
];

function FAQs() {
  const [openNavbar, setOpenNavbar] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box
      position="relative"
      overflow="hidden"
      bgGradient="linear(to-r, teal.500, blue.500)"
    >
      <Box
        textAlign="center"
        opacity={openNavbar ? "0.1" : "1"}
        transition="opacity 0.3s ease-in-out"
      >
        <Header openNavbar={openNavbar} setOpenNavbar={setOpenNavbar} />
      </Box>

      <Box>
        <Navbar openNavbar={openNavbar} setOpenNavbar={setOpenNavbar} />
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        textAlign="center"
        marginBottom="10%"
        paddingTop={{ base: "40%", lg: "10%" }}
        opacity={openNavbar ? "0.1" : "1"}
        transition="opacity 0.3s ease-in-out"
        onClick={() => setOpenNavbar(false)}
        position="relative"
        zIndex={openNavbar ? 0 : 1}
      >
        <Box mb={4}>
          <Button
            colorScheme="teal"
            variant="solid"
            textColor="white"
            borderRadius="full"
            boxShadow="lg"
            size="lg"
            transform="scale(1)"
            transition="all 0.3s ease"
            _hover={{
              transform: "scale(1.1)",
              boxShadow: "2xl",
            }}
            _active={{
              transform: "scale(0.95)",
            }}
          >
            FAQs
          </Button>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          fontSize={{ base: "2xl", lg: "5xl" }}
          fontWeight="bold"
          color="white"
          letterSpacing="wide"
          mb={2}
          lineHeight="shorter"
        >
          <h1>Frequently Asked</h1>
          <h1>Questions</h1>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          marginTop="5%"
          paddingX="5%"
        >
          {faqs.map((faq, index) => (
            <Question
              question={faq.question}
              answer={faq.answer}
              key={index}
              index={index}
              onOpen={onOpen}
              onClose={onClose}
              isOpen={isOpen}
            />
          ))}
        </Box>
      </Box>

      <Box
        textAlign="center"
        opacity={openNavbar ? "0.1" : "1"}
        transition="opacity 0.3s ease-in-out"
        onClick={() => setOpenNavbar(false)}
      >
        <Footer />
      </Box>
    </Box>
  );
}

export default FAQs;
