import React from "react";
import { Box, Flex, Heading, Text, VStack, Stack } from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/color-mode";

const plansArray = [
  {
    plan_intro: "Free Plan",
    planFeatures: ["Unlimited chat with mentees"],
    planOriginalPrice: 100,
    planReducedPrice: 0,
    planColor: "#FAF8D9",
    planButtonColor: "#FAF8D9",
  },
  {
    plan_intro: "Starter Plan",
    planFeatures: [
      "Max 2 meetings with one mentee",
      "Unlimited chat with 1 mentee",
      "Validity: 2 Days",
    ],
    planOriginalPrice: 499,
    planReducedPrice: 299,
    planButtonAction: "Select Plan",
    planColor: "#FAF8D9",
    planButtonColor: "#FAF8D9",
  },
  {
    plan_intro: "Beginner's Plan",
    planFeatures: [
      "Max 5 meetings with one mentee",
      "Unlimited chat with 1 mentee",
      "Test Series Evaluation",
      "Personalized Study Plan",
      "Handwritten Notes",
      "Validity: 1 Month",
    ],
    planOriginalPrice: 5999,
    planReducedPrice: 1999,
    planButtonAction: "Select Plan",
    planColor: "#FAF8D9",
    planButtonColor: "#FAF8D9",
  },
  {
    plan_intro: "Accelerator Plan",
    planFeatures: [
      "Max 20 meetings with one mentee",
      "Unlimited chat with 1 mentee",
      "Test Series Evaluation",
      "Personalized Study Plan",
      "Handwritten Notes",
      "Validity: 6 Months",
    ],
    planOriginalPrice: 13499,
    planReducedPrice: 8999,
    planButtonAction: "Select Plan",
    planColor: "#FAF8D9",
    planButtonColor: "#FAF8D9",
  },
  {
    plan_intro: "Mega Plan",
    planFeatures: [
      "Max 40 meetings with one mentee",
      "Unlimited chat with 1 mentee",
      "Test Series Evaluation",
      "Personalized Study Plan",
      "Handwritten Notes",
      "Validity: 12 Months",
    ],
    planOriginalPrice: 22499,
    planReducedPrice: 12999,
    planButtonAction: "Select Plan",
    planColor: "#8CC28E",
    planButtonColor: "#FDB34A",
  },
];

export const MentorEffortAndEarnings = () => {
  const textColor = useColorModeValue("gray.700", "gray.100");

  return (
    <Flex direction="column" align="center" py={8} px={4}>
      <Heading size="lg" mb={8} textAlign="center">
        What You Need to Do to Earn as a Mentor
      </Heading>

      <Stack
        direction={["column", "column", "row"]}
        wrap="wrap"
        justify="center"
        spacing={[6, 8, 10]}
        width="100%"
      >
        {plansArray.map((plan, index) => (
          <Box
            key={index}
            bg={plan.planColor}
            p={[4, 6, 8]}
            maxW={["100%", "350px", "300px"]}
            borderRadius="md"
            boxShadow="xl"
            _hover={{ transform: "scale(1.05)", transition: "0.3s" }}
          >
            <VStack align="start" spacing={4}>
              <Heading size="sm" color={textColor}>
                If mentee buys {plan.plan_intro}
              </Heading>
              <Text color={textColor} fontWeight="bold">
                You Will Earn: ₹{plan.planReducedPrice}
              </Text>

              <VStack align="start" spacing={2}>
                {plan.planFeatures.map((feature, i) => (
                  <Text key={i} color={textColor}>
                    {feature.includes("meetings")
                      ? `🕒 ${feature}`
                      : `✅ ${feature}`}
                  </Text>
                ))}
              </VStack>

              <Text fontSize="sm" color={textColor}>
                Required Total Time Commitment:
                {plan.plan_intro === "Free Plan"
                  ? " Minimal effort (chat only)"
                  : plan.plan_intro === "Starter Plan"
                  ? " 1-2 Hours"
                  : plan.plan_intro === "Beginner's Plan"
                  ? " 5-6 Hours"
                  : plan.plan_intro === "Accelerator Plan"
                  ? " 20-30 Hours"
                  : " 45-50 Hours"}
              </Text>

              <Text fontSize="sm" color={textColor} fontWeight="bold">
                You will engage in:
                {plan.plan_intro === "Free Plan"
                  ? " Chat only"
                  : plan.plan_intro === "Starter Plan"
                  ? " 2 meetings & unlimited chat"
                  : plan.plan_intro === "Beginner's Plan"
                  ? " 5 meetings, chat, evaluations"
                  : plan.plan_intro === "Accelerator Plan"
                  ? " 20 meetings, evaluations, full mentoring"
                  : " 40 meetings, evaluations, full mentoring"}
              </Text>
            </VStack>
          </Box>
        ))}
      </Stack>
    </Flex>
  );
};
