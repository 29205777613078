import {
  Box,
  Button,
  Input,
  useToast,
  Select,
  useColorModeValue,
  Image,
} from "@chakra-ui/react";
import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Carousal from "../utils/Carousal";
import { hitBeApi } from "../../apis/api";
import { useDispatch, useSelector } from "react-redux";
import {
  isCookieExpired,
  validatePhoneNumberWithCountryCode,
} from "../../utils/utils";
import GoogleLoginComponent from "./LoginWithGoogle";

const countryCodes = [
  "+1",
  "+91",
  "+81",
  "+65",
  "+49",
  "+33",
  "+39",
  "+31",
  "+41",
  "+44",
];

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const savedPhoneNumber = useSelector((state) => state.loginPhoneNumber);

  const [formError, setFormError] = useState("");
  const [loginMethod, setLoginMethod] = useState("phone");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [isSendingOtp, setIsSendingOtp] = useState(false);

  const phoneNumberRef = useRef(null);

  useEffect(() => {
    if (savedPhoneNumber && savedPhoneNumber.length > 0) {
      setPhoneNumber(savedPhoneNumber);
    }
  }, [savedPhoneNumber]);

  useEffect(() => {
    if (!isCookieExpired("token")) {
      navigate("/dashboard");
    }
  }, [navigate]);

  useEffect(() => {
    if (phoneNumberRef.current) {
      phoneNumberRef.current.focus();
    }
  }, []);

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    if (value.length <= 10) {
      setPhoneNumber(value);
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleKeyDown = async (e) => {
    if (e.key === "Enter") {
      await handleContinueClick();
    }
  };

  const handleContinueClick = async () => {
    if (isSendingOtp) return; // Prevent multiple clicks
    setIsSendingOtp(true); // Disable button immediately

    if (loginMethod === "phone") {
      const fullPhoneNumber = `${countryCode}${phoneNumber}`;
      if (
        phoneNumber.length > 0 &&
        validatePhoneNumberWithCountryCode(fullPhoneNumber)
      ) {
        setFormError("");
        try {
          await hitBeApi("phone/otp", "POST", { phoneNumber: fullPhoneNumber });
          toast({
            title: "OTP sent successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          dispatch({
            type: "Set_Login_Phone_Number",
            value: fullPhoneNumber,
          });
          navigate("/verify-otp");
        } catch (error) {
          console.log("Error sending OTP.", error);
          setFormError(error.message);
          toast({
            title: "Error sending OTP.",
            description: error.message,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } finally {
          setIsSendingOtp(false); // Re-enable button
        }
      } else {
        setFormError("Invalid phone number provided");
        setIsSendingOtp(false); // Re-enable button in case of error
      }
    } else if (loginMethod === "email") {
      if (email.length > 0 && email.includes("@")) {
        setFormError("");
        try {
          await hitBeApi("email/otp", "POST", { email });
          toast({
            title: "OTP sent successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          // Save email if needed in the state
          dispatch({
            type: "Set_Login_Email",
            value: email,
          });
          navigate("/verify-otp");
        } catch (error) {
          console.log(error);
          setFormError(error.message);
          toast({
            title: "Error sending OTP.",
            description: error.message,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } finally {
          setIsSendingOtp(false); // Re-enable button
        }
      } else {
        setFormError("Invalid email address provided");
        setIsSendingOtp(false); // Re-enable button in case of error
      }
    }
    setIsSendingOtp(false);
  };

  return (
    <Box
      display="flex"
      flexDirection={{ base: "column", lg: "row" }}
      m="5% 5%"
      justifyContent="space-between"
    >
      <Box
        bg={useColorModeValue("white", "gray.700")}
        p={{ base: "30px", lg: "40px" }}
        borderRadius="30px"
        width={{ base: "100%", lg: "45%" }}
        shadow="lg"
      >
        <Box
          mb="20px"
          fontSize={{ base: "3xl", lg: "4xl" }}
          fontWeight="bold"
          textAlign={{ base: "left", lg: "center" }}
          color={useColorModeValue("teal.500", "teal.200")}
        >
          <Link to="/">
            <Image
              height={"30px"}
              src="/logo.png"
              alt="logo"
              mr="5px"
              display={"inline"}
            ></Image>
            MenteeMentors
          </Link>
        </Box>

        <Box
          mb="15px"
          fontSize={{ base: "2xl", lg: "3xl" }}
          fontWeight="extrabold"
          textAlign={{ base: "left", lg: "center" }}
        >
          <h1>Welcome to the</h1>
          <Box as="span" color="teal.500" pl={{ base: "0", lg: "5px" }}>
            India's Largest
          </Box>
          <h1> Mentorship and Career Guidance Platform</h1>
        </Box>

        <Box mb="20px" fontSize={{ base: "md", lg: "lg" }} fontWeight="bold">
          <h1>All Exam Aspirants Trust us for their Preparation</h1>
        </Box>

        <Box mb="20px">
          <Select
            placeholder="Select login method"
            value={loginMethod}
            onChange={(e) => setLoginMethod(e.target.value)}
            size="lg"
            variant="filled"
          >
            <option value="phone">Phone Number</option>
            <option value="email">Email</option>
          </Select>
        </Box>

        {loginMethod === "phone" && (
          <>
            <Box mb="15px">
              <Select
                placeholder="Select country code"
                value={countryCode}
                onChange={(e) => setCountryCode(e.target.value)}
                size="lg"
                variant="filled"
              >
                {countryCodes.map((code) => (
                  <option key={code} value={code}>
                    {code}
                  </option>
                ))}
              </Select>
            </Box>
            <Box mb="15px">
              <Input
                type="number"
                placeholder="Enter Mobile Number"
                value={phoneNumber}
                required
                ref={phoneNumberRef}
                onChange={handlePhoneNumberChange}
                onKeyDown={handleKeyDown}
                size="lg"
                variant="filled"
              />
            </Box>
          </>
        )}

        {loginMethod === "email" && (
          <>
            <Box mb="15px">
              <Input
                type="email"
                placeholder="Enter Email Address"
                value={email}
                required
                onChange={handleEmailChange}
                onKeyDown={handleKeyDown}
                size="lg"
                variant="filled"
              />
            </Box>
          </>
        )}

        <Button
          mt="20px"
          size="lg"
          width="full"
          colorScheme="teal"
          fontSize="xl"
          fontWeight="extrabold"
          onClick={handleContinueClick}
          isLoading={isSendingOtp}
        >
          Continue
        </Button>

        {formError && (
          <Box color="red.500" mt="10px" textAlign="center">
            {formError}
          </Box>
        )}
        <Box fontSize={"large"} textAlign={"center"} my="20px">
          <h1>OR</h1>
        </Box>
        <Box mt="20px" w="100%">
          <GoogleLoginComponent></GoogleLoginComponent>
        </Box>

        <Box mt="30px" fontSize={{ base: "sm", lg: "md" }} textAlign="center">
          Don’t have an account?{" "}
          <Link to="/" color="teal.500" fontWeight="bold">
            Sign up now
          </Link>
        </Box>
      </Box>

      <Box
        width={{ base: "100%", lg: "50%" }}
        mt={{ base: "10%", lg: "0" }}
        p="20px"
        borderRadius="30px"
        shadow="lg"
      >
        <Carousal />
      </Box>
    </Box>
  );
}

export default Login;
